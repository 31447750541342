var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mb-12",staticStyle:{"position":"relative"}},[_c('v-select',{attrs:{"items":_vm.maps,"label":"Import Map","item-text":"name","item-value":"_id","return-object":"","outlined":"","clearable":"","dense":""},model:{value:(_vm.importmap),callback:function ($$v) {_vm.importmap=$$v},expression:"importmap"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.settings.map,"loading-text":"Loading... Please wait","hide-default-footer":"","hide-default-header":"","item-key":"index","footer-props":{ itemsPerPageOptions: _vm.rows }},scopedSlots:_vm._u([{key:"item.col",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.settings.csvheaders,"label":"CSV Column","outlined":"","dense":""},on:{"change":function($event){item.value = null}},model:{value:(item.col),callback:function ($$v) {_vm.$set(item, "col", $$v)},expression:"item.col"}})]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mt-4"},[(item.field && !item.col)?_c(_vm.fieldsObj[item.field].setup.type,{tag:"component",attrs:{"record":item,"value":item.value,"fieldSettings":_vm.fieldsObj[item.field],"formSettings":Object.assign({}, item, {show: true, name: 'Value'})},on:{"emit":function($event){return _vm.catchEmitFilter($event, item)}}}):_vm._e()],1)]}},{key:"item.subrecord",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":_vm.subrecords,"label":"Subrecord","item-text":"name","item-value":"field","outlined":"","hide-details":item.subrecord ? true : false,"dense":"","clearable":""},model:{value:(item.subrecord),callback:function ($$v) {_vm.$set(item, "subrecord", $$v)},expression:"item.subrecord"}}),(item.subrecord)?_c('v-switch',{attrs:{"label":"Overiwrite (Uwaga! może wyczyścić rekord)"},model:{value:(item.overwrite),callback:function ($$v) {_vm.$set(item, "overwrite", $$v)},expression:"item.overwrite"}}):_vm._e()]}},{key:"item.field",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4",attrs:{"items":item.subrecord ? _vm.columns[item.subrecord] : _vm.fields,"label":"Field","item-text":"name","item-value":"field","outlined":"","dense":""},on:{"change":function($event){item.value = null}},model:{value:(item.field),callback:function ($$v) {_vm.$set(item, "field", $$v)},expression:"item.field"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.delMapLine(item)}}},[_vm._v("mdi-delete")])]}}])}),_c('v-fab-transition',[_c('v-btn',{attrs:{"color":"pink","dark":"","absolute":"","bottom":"","right":"","fab":""},on:{"click":_vm.addMapLine}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
/* eslint-disable */
// Layout Components
import Toolbar from "../components/layout/Toolbar";
import Navigation from "../components/layout/NavigationDrawer";
import Breadcrumbs from "../components/layout/Breadcrumbs";

// import headerEmployee from "../components/record/headers/employee";
// import headerAddress from "../components/record/headers/address";
// import headerCustomer from "../components/record/headers/customer";
// import headerCompany from "../components/record/headers/company";
// import headerVendor from "../components/record/headers/vendor";

// import headerInventoryitem from "../components/record/headers/inventoryitem";
// import headerKititem from "../components/record/headers/kititem";
// import headerSalesorder from "../components/record/headers/salesorder";
// import headerInvoice from "../components/record/headers/invoice";
// import headerBill from "../components/record/headers/bill";
// import headerPurchaseorder from "../components/record/headers/purchaseorder";
// import headerFulfillment from "../components/record/headers/itemfulfillment";
// import headerPrepayment from "../components/record/headers/prepayment";
// import headerItemreceipt from "../components/record/headers/itemreceipt";
// import headerCreditmemo from "../components/record/headers/creditmemo";
// import headerDelivery from "../components/record/headers/delivery";
// import headerEstimate from "../components/record/headers/estimate";
// import headerInventoryadjustment from "../components/record/headers/inventoryadjustment";
// import headerInventorytransfer from "../components/record/headers/inventorytransfer";
// import headerCustomerpayment from "../components/record/headers/customerpayment";
// import headerCustomerrefund from "../components/record/headers/customerrefund";

import DataTable from "../components/dataTable/View";
import Calendar from "../components/calendar/View";
import GoogleMap from "../components/maps/View";
import Record from "../components/record/View";
import QueryResult from "../pages/queries/view-results/View";
import FieldCountry from "../components/fieldTypes/country";
import FieldCountries from "../components/fieldTypes/countries";
import FieldText from "../components/fieldTypes/text";
import FieldEmail from "../components/fieldTypes/email";
import FieldSelect from "../components/fieldTypes/select";
import FieldMultiSelect from "../components/fieldTypes/multiselect";
import FieldAutocomplete from "../components/fieldTypes/autocomplete";
import FieldMap from "../components/fieldTypes/map";
import FieldFile from "../components/fieldTypes/file";
import FieldTextarea from "../components/fieldTypes/textarea";
import FieldBoolean from "../components/fieldTypes/switch";
import FieldDate from "../components/fieldTypes/date";
import FieldTime from "../components/fieldTypes/time";
import FieldInteger from "../components/fieldTypes/integer";
import FieldCurrency from "../components/fieldTypes/currency";
import FieldPercent from "../components/fieldTypes/percent";
import FieldCombobox from "../components/fieldTypes/combobox";
import FieldColor from "../components/fieldTypes/color";
import FieldHtml from "../components/fieldTypes/html";
import FieldSubrecord from "../components/fieldTypes/subrecord";

import ToolInbox from "../components/inbox/View";
import ToolItemFulfillmentScan from "../components/tools/itemfulfillmentscan/View";
import ToolEmailTemplate from "../components/emailtemplate/View";
import ToolOpenOrderStatus from "../components/tools/openordersstatus/View";
import ToolItemsInDeliveries from "../components/tools/itemsindeliveries/View";
import ToolStatement from "../components/tools/statement/View";
import ToolPricelist from "../components/tools/pricelist/View";
import ToolItemInformations from "../components/tools/iteminformations/View";
import ToolRelatedTransactions from "../components/tools/relatedtransactions/View";
import ToolAssignPayment from "../components/tools/assignpayment/View";
import ToolAssignRefund from "../components/tools/assignrefund/View";
import ToolAssignPrepayment from "../components/tools/assignprepayment/View";
import ToolAssignExpeneses from "../components/tools/assignexpenses/View";
import ToolClipboardTable from "../components/tools/clipboardtable/View";
import ToolOpenInvoices from "../components/tools/openinvoices/View";
import ToolOpenPayments from "../components/tools/openpayments/View";
import ToolLogs from "../components/tools/recordlogs/View";
import ToolReceiveDelivery from "../components/tools/receivedelivery/View";
import ToolPricing from "../components/tools/pricing/View";
import ToolActivities from "../components/tools/activities/View";
import ToolSalesTargets from "../components/tools/salestargets/View";
import ToolTrackings from "../components/tools/trackings/View";
import ToolUPS from "../components/tools/upsconnect/View";
import ToolOpenQuery from "../components/tools/openquery/View";
import ToolPallets from "../components/tools/pallets/View";
import ToolLoginAs from "../components/tools/loginas/View";
import ToolLevam from "../components/tools/levam/View";
import ToolCarrierRequest from "../components/tools/carrierequest/View";

function setupComponents(Vue) {
  Vue.component("toolbar", Toolbar);
  Vue.component("navigation", Navigation);
  Vue.component("breadcrumbs", Breadcrumbs);

  // Vue.component("header-employee", headerEmployee);
  // Vue.component("header-address", headerAddress);
  // Vue.component("header-customer", headerCustomer);
  // Vue.component("header-company", headerCompany);
  // Vue.component("header-vendor", headerVendor);
  // Vue.component("header-salesorder", headerSalesorder);
  // Vue.component("header-purchaseorder", headerPurchaseorder);
  // Vue.component("header-inventoryitem", headerInventoryitem);
  // Vue.component("header-kititem", headerKititem);
  // Vue.component("header-invoice", headerInvoice);
  // Vue.component("header-bill", headerBill);
  // Vue.component("header-itemfulfillment", headerFulfillment);
  // Vue.component("header-prepayment", headerPrepayment);
  // Vue.component("header-itemreceipt", headerItemreceipt);
  // Vue.component("header-creditmemo", headerCreditmemo);
  // Vue.component("header-delivery", headerDelivery);
  // Vue.component("header-estimate", headerEstimate);
  // Vue.component("header-inventoryadjustment", headerInventoryadjustment);
  // Vue.component("header-inventorytransfer", headerInventorytransfer);
  // Vue.component("header-customerpayment", headerCustomerpayment);
  // Vue.component("header-customerrefund", headerCustomerrefund);

  Vue.component("datatable", DataTable);
  Vue.component("calendar", Calendar);
  Vue.component("googlemap", GoogleMap);
  Vue.component("record", Record);
  Vue.component("queryresult", QueryResult);

  Vue.component("field-country", FieldCountry);
  Vue.component("field-countries", FieldCountries);
  Vue.component("field-text", FieldText);
  Vue.component("field-email", FieldEmail);
  Vue.component("field-select", FieldSelect);
  Vue.component("field-multiselect", FieldMultiSelect);
  Vue.component("field-autocomplete", FieldAutocomplete);
  Vue.component("field-map", FieldMap);
  Vue.component("field-file", FieldFile);
  Vue.component("field-textarea", FieldTextarea);
  Vue.component("field-boolean", FieldBoolean);
  Vue.component("field-date", FieldDate);
  Vue.component("field-time", FieldTime);
  Vue.component("field-int", FieldInteger);
  Vue.component("field-currency", FieldCurrency);
  Vue.component("field-percent", FieldPercent);
  Vue.component("field-combobox", FieldCombobox);
  Vue.component("field-color", FieldColor);
  Vue.component("field-html", FieldHtml);
  Vue.component("field-subrecord", FieldSubrecord);

  Vue.component("tool-inbox", ToolInbox);
  Vue.component("tool-itemfulfillmentscan", ToolItemFulfillmentScan);
  Vue.component("tool-emailtemplate", ToolEmailTemplate);
  Vue.component("tool-openorderstatus", ToolOpenOrderStatus);
  Vue.component("tool-itemsindeliveries", ToolItemsInDeliveries);
  Vue.component("tool-statement", ToolStatement);
  Vue.component("tool-pricelist", ToolPricelist);
  Vue.component("tool-iteminformations", ToolItemInformations);
  Vue.component("tool-relatedtransactions", ToolRelatedTransactions);
  Vue.component("tool-assignpayment", ToolAssignPayment);
  Vue.component("tool-assignrefund", ToolAssignRefund);
  Vue.component("tool-assignprepayment", ToolAssignPrepayment);
  Vue.component("tool-assignexpenses", ToolAssignExpeneses);
  Vue.component("tool-clipboardtable", ToolClipboardTable);
  Vue.component("tool-openinvoices", ToolOpenInvoices);
  Vue.component("tool-openpayments", ToolOpenPayments);
  Vue.component("tool-logs", ToolLogs);
  Vue.component("tool-receivedelivery", ToolReceiveDelivery);
  Vue.component("tool-pricing", ToolPricing);
  Vue.component("tool-activities", ToolActivities);
  Vue.component("tool-salestargets", ToolSalesTargets);
  Vue.component("tool-trackings", ToolTrackings);
  Vue.component("tool-ups", ToolUPS);
  Vue.component("tool-openquery", ToolOpenQuery);
  Vue.component("tool-pallets", ToolPallets);
  Vue.component("tool-loginas", ToolLoginAs);
  Vue.component("tool-levam", ToolLevam);
  Vue.component("tool-carrierrequest", ToolCarrierRequest);
  
}

export { setupComponents };

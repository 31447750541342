 <template>
  <v-container fluid id="reportview">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate absolute></v-progress-circular>
    </v-overlay>
    <v-row>
      <v-col>
        <v-select
          :items="companies"
          v-model="company"
          label="Company"
          item-text="name"
          item-value="_id"
          outlined
          clearable
          multiple
          hide-details
          class="mt-2 mb-2"
          dense
          @change="changed = true"
        ></v-select>
        <v-switch
          v-model="intercompany"
          dense
          hide-details
          label="Inter-Company Transactions"
          @change="changed = true"
        ></v-switch>
      </v-col>
      <v-col>
        <v-menu
          v-model="menufrom"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date from"
              placeholder
              v-model="datefrom"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datefrom"
            :first-day-of-week="1"
            @change="changed = true"
            min="2022-01-01"
          ></v-date-picker>
        </v-menu>
        <v-menu
          v-model="menuto"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Date to"
              placeholder
              v-model="dateto"
              append-icon="event"
              readonly
              v-on="on"
              outlined
              dense
              hide-details
              clearable
              class="mt-2 mb-2"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateto"
            :first-day-of-week="1"
            @change="changed = true"
            min="2022-01-01"
            max="2024-12-31"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-select
          :items="periods"
          v-model="period"
          label="Periods"
          item-text="name"
          item-value="_id"
          hide-details
          class="mt-2 mb-2"
          outlined
          clearable
          dense
          return-object
        ></v-select>
        <v-switch
          v-model="compare"
          dense
          hide-details
          label="Annual Comparison"
        ></v-switch>
        <!-- <v-switch v-model="quarterly" label="Quarterly"></v-switch> -->
      </v-col>
      <v-col>
        <v-select
          :items="manufacturers"
          v-model="manufacturer"
          label="Manufacturers"
          class="mt-2 mb-2"
          outlined
          clearable
          multiple
          dense
          hide-details
        >
          <template v-slot:append-outer>
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                :key="`icon-${excludemanufacturer}`"
                :color="excludemanufacturer ? 'info' : 'success'"
                @click="excludemanufacturer = !excludemanufacturer"
                v-text="
                  excludemanufacturer ? 'mdi-minus-circle' : 'mdi-plus-circle'
                "
              ></v-icon>
            </v-slide-x-reverse-transition>
          </template>
        </v-select>
        <v-autocomplete
          :items="countries"
          v-model="country"
          label="Country"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          multiple
          dense
        >
          <template v-slot:append-outer>
            <v-slide-x-reverse-transition mode="out-in">
              <v-icon
                :key="`icon-${excludecountry}`"
                :color="excludecountry ? 'info' : 'success'"
                @click="excludecountry = !excludecountry"
                v-text="excludecountry ? 'mdi-minus-circle' : 'mdi-plus-circle'"
              ></v-icon>
            </v-slide-x-reverse-transition>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col>
        <v-select
          :items="salesreps"
          v-model="salesrep"
          item-text="name"
          item-value="_id"
          label="Sales Rep"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          dense
        ></v-select>
        <v-select
          :items="createdbys"
          v-model="createdby"
          label="Created By"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          dense
        ></v-select>
        <v-select
          :items="modes"
          v-model="mode"
          label="Transactions Type"
          item-text="name"
          item-value="_id"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          return-object
          dense
        ></v-select>
      </v-col>
      <v-col>
        <v-select
          :items="sources"
          v-model="source"
          label="Sources"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          multiple
          dense
        >
          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="toggle">
              <v-list-item-action>
                <v-icon :color="source.length > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> Select All </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
        </v-select>
        <v-select
          :items="pervalues"
          v-model="pervalue"
          item-text="name"
          item-value="_id"
          label="Per Value"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          dense
        ></v-select>
        <v-select
          :items="customertypes"
          v-model="customerstype"
          item-text="name"
          item-value="_id"
          label="Customers Type"
          class="mt-2 mb-2"
          outlined
          clearable
          hide-details
          dense
          @change="changed = true"
        ></v-select>
        <v-btn
          @click="getData()"
          block
          tile
          color="primary"
          class="mt-2 mb-2"
          v-if="changed"
          >Generate</v-btn
        >
      </v-col>
      <!-- <v-col>
        <v-btn
          @click="printReport()"
          block
          tile
          color="primary"
          class="mt-2 mb-2"
          >Print</v-btn
        >
      </v-col> -->
      <v-col class="text-right" v-if="false">
        <v-btn @click="show = !show" tile color="primary" class="mt-2 mb-2">
          Reports
          <v-icon right>{{
            show ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="ma-0 pa-0">
        <v-expand-transition>
          <div v-show="show">
            <v-divider></v-divider>
            <v-tabs dark v-model="active" v-if="show">
              <v-tab :key="1">Sales by Item Summary</v-tab>
              <v-tab :key="2">Sales Backordered</v-tab>
              <v-tab :key="3">Order Summary</v-tab>
              <v-tab :key="4">Distributors by Country</v-tab>
              <v-tab :key="5">Customers</v-tab>
              <v-tab :key="6">Invoices</v-tab>
              <v-tab :key="7">Sources</v-tab>
              <v-tab-item :key="1">
                <queryresult
                  used="customer"
                  queryid="5fae502af38e251d30d28ec8"
                  v-if="active == 0"
                />
              </v-tab-item>
              <v-tab-item :key="2">
                <queryresult
                  used="customer"
                  queryid="603de82294e10a2a5cff7657"
                  :filters="filters"
                  v-if="active == 1"
                />
              </v-tab-item>
              <v-tab-item :key="3">
                <queryresult
                  used="customer"
                  queryid="607d8360f032760dd4870949"
                  :filters="filters"
                  v-if="active == 2"
                />
              </v-tab-item>
              <v-tab-item :key="4">
                <queryresult
                  used="customer"
                  queryid="5fdb207d14ea562948f6900b"
                  :filters="filters"
                  v-if="active == 3"
                />
              </v-tab-item>
              <v-tab-item :key="5">
                <queryresult
                  used="customer"
                  queryid="5fd07cbf6f6f513be48668fc"
                  :filters="filters"
                  v-if="active == 4"
                />
              </v-tab-item>
              <v-tab-item :key="6">
                <queryresult
                  used="customer"
                  queryid="5fd07cbf6f6f513be48668fd"
                  :filters="filters"
                  v-if="active == 5"
                />
              </v-tab-item>
              <v-tab-item :key="7">
                <queryresult
                  used="customer"
                  queryid="5f646f8ba86c3716e46d1847"
                  :filters="filters"
                  v-if="active == 6"
                />
              </v-tab-item>
            </v-tabs>
          </div>
        </v-expand-transition>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div
      v-if="!loading && results.length && !changed"
      ref="reportview"
      id="printarea"
      fluid
    >
      <v-row>
        <v-col md="6">
          <v-subheader>
            Total {{ mode.text }} by
            {{ pervalues.find((v) => v._id == pervalue).name }}</v-subheader
          >
          <doughnutchart
            :chartData="
              toChartData('doughnutchart', results, pervalue, 'amount')
            "
          />
        </v-col>
        <v-col md="6">
          <barchart
            :chartData="
              toChartData('barchart', results, period.field, 'amount', pervalue)
            "
            :option="options({ stacked: true })"
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row v-if="mode._id == 'orders'">
        <v-col md="6">
          <barchart
            :chartData="
              toChartData(
                'barchart',
                results,
                period.field,
                'amountbackorder',
                compare
                  ? 'year'
                  : [
                      {
                        name: 'BO Amount',
                        field: 'amountbackorder',
                        type: 'line',
                      },
                      {
                        name: 'Committed Amount',
                        field: 'amountcommitted',
                        type: 'line',
                      },
                      { name: 'Total Amount', field: 'amount' },
                    ],
                colors[index]
              )
            "
            :option="options()"
          />
        </v-col>
        <v-col md="6">
          <v-subheader>Backordered / Committed</v-subheader>
          <doughnutchart
            :chartData="
              toChartData(
                'doughnutchart',
                results,
                [
                  {
                    name: 'BO',
                    field: 'amountbackorder',
                    type: 'line',
                  },
                  {
                    name: 'Committed',
                    field: 'amountcommitted',
                    type: 'line',
                  },
                ],
                'amountbackorder'
              )
            "
          />
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col md="6">
          <v-subheader>Total {{ mode.text }} Overview</v-subheader>
          <v-data-table
            :headers="headers2"
            :items="getSales(results)"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="name"
            hide-default-footer
          >
            <template v-slot:item.amount="{ item }">
              <span
                @click="
                  dialgodata = getCustomerData(
                    customers,
                    item.period,
                    item.currentperiod,
                    item.currentyear
                  );
                  dialog = !dialog;
                "
              >
                {{
                  new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                    style: "currency",
                    currency: currency || "PLN",
                  }).format(item.amount)
                }}
                ({{ item.count }})
              </span>
            </template>
            <template v-slot:item.compareamount="{ item }">
              <span
                @click="
                  dialgodata = getCustomerData(
                    customers,
                    item.period,
                    item.currentperiod,
                    item.currentyear
                  );
                  dialog = !dialog;
                "
              >
                {{
                  new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                    style: "currency",
                    currency: currency || "PLN",
                  }).format(item.compareamount)
                }}
                ({{ item.comparecount }})
              </span>
            </template>
            <template v-slot:item.procquantity="{ item }">
              <v-chip
                label
                v-if="item.avgquantity"
                :color="
                  (item.quantity / item.avgquantity) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.quantity / item.avgquantity) * 100) - 100
                }}%</v-chip
              >
            </template>
            <template v-slot:item.procamount="{ item }">
              <v-chip
                label
                v-if="item.avgamount"
                :color="
                  (item.amount / item.avgamount) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.amount / item.avgamount) * 100) - 100
                }}%</v-chip
              >
            </template>
            <template v-slot:item.proccompareamount="{ item }">
              <v-chip
                label
                v-if="item.compareamount"
                :color="
                  (item.amount / item.compareamount) * 100 - 100 < 0
                    ? 'red'
                    : 'green'
                "
                dark
                >{{
                  Math.round((item.amount / item.compareamount) * 100) - 100
                }}% ({{
                  Math.round((item.count / item.comparecount) * 100) - 100
                }}%)</v-chip
              >
            </template>
          </v-data-table>
        </v-col>
        <v-col md="6">
          <v-subheader>Top Items</v-subheader>
          <v-data-table
            :headers="headers"
            :items="getItems"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :footer-props="{ itemsPerPageOptions: rows }"
          >
            <template v-slot:item.date="{ item }">
              <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
            </template>
            <template v-slot:item.item="{ item }">
              <router-link
                v-if="item.item && item.item._id"
                :to="{
                  path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                }"
                class="link"
                >{{ item.item.displayname || item.item.name }}</router-link
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row v-if="mode == 'sales'">
        <v-col v-if="items.byordered">
          <v-subheader>By Ordered</v-subheader>
          <barchart
            :chartData="
              toChartData('barchart', results, period.field, 'quantity')
            "
            :option="options()"
          />
        </v-col>
      </v-row>
      <v-row v-for="(value, index) of getArrayLoop" :key="value._id">
        <v-row
          v-if="
            (res = results.filter((item) => {
              return item[pervalue] && item[pervalue] == value;
            })).length
          "
        >
          <v-col md="6">
            <barchart
              :chartData="
                toChartData(
                  'barchart',
                  res,
                  period.field,
                  'amount',
                  compare
                    ? 'year'
                    : [
                        {
                          name: 'Transactions',
                          field: 'transaction',
                          type: 'line',
                          yAxisID: 'y1',
                        },
                        {
                          name: 'Profit',
                          field: 'profit',
                          type: 'line',
                        },
                        { name: 'Amount', field: 'amount' },
                      ],
                  colors[index]
                )
              "
              :option="options(compare ? {} : { yAxisID: 'y1' })"
            />
          </v-col>

          <v-col md="6">
            <v-subheader>{{ value }} {{ mode.text }} Overview</v-subheader>
            <v-data-table
              :headers="headers2"
              :items="
                getSales(
                  results.filter((item) => {
                    return item[pervalue] && item[pervalue] == value;
                  })
                )
              "
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="name"
              hide-default-footer
            >
              <template v-slot:item.amount="{ item }">
                <span
                  @click="
                    dialgodata = getCustomerData(
                      customers,
                      item.period,
                      item.currentperiod,
                      item.currentyear,
                      value
                    );
                    dialog = !dialog;
                  "
                >
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.amount)
                  }}
                  ({{ item.count }})<br />
                  <span v-if="item.profit">
                    {{
                      new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                        style: "currency",
                        currency: currency || "PLN",
                      }).format(item.profit)
                    }}(~{{ Math.round((item.profit * 100) / item.amount) }}%)
                  </span>
                </span>
              </template>
              <template v-slot:item.compareamount="{ item }">
                <span
                  @click="
                    dialgodata = getCustomerData(
                      customers,
                      item.period,
                      item.currentperiod,
                      item.currentyear,
                      value
                    );
                    dialog = !dialog;
                  "
                >
                  {{
                    new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                      style: "currency",
                      currency: currency || "PLN",
                    }).format(item.compareamount)
                  }}
                  ({{ item.comparecount }})<br />
                  <span v-if="item.compareprofit">
                    {{
                      new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                        style: "currency",
                        currency: currency || "PLN",
                      }).format(item.compareprofit)
                    }}
                    (~{{
                      Math.round(
                        (item.compareprofit * 100) / item.compareamount
                      )
                    }}%)
                  </span>
                </span>
              </template>
              <template v-slot:item.procquantity="{ item }">
                <v-chip
                  label
                  v-if="item.avgquantity"
                  :color="
                    (item.quantity / item.avgquantity) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.quantity / item.avgquantity) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.procamount="{ item }">
                <v-chip
                  label
                  v-if="item.avgamount"
                  :color="
                    (item.amount / item.avgamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.avgamount) * 100) - 100
                  }}%</v-chip
                >
              </template>
              <template v-slot:item.proccompareamount="{ item }">
                <v-chip
                  label
                  v-if="item.compareamount"
                  :color="
                    (item.amount / item.compareamount) * 100 - 100 < 0
                      ? 'red'
                      : 'green'
                  "
                  dark
                  >{{
                    Math.round((item.amount / item.compareamount) * 100) - 100
                  }}% ({{
                    Math.round((item.count / item.comparecount) * 100) - 100
                  }}%)</v-chip
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="mode._id == 'orders'">
          <v-col md="6">
            <barchart
              :chartData="
                toChartData(
                  'barchart',
                  results.filter((item) => {
                    return item[pervalue] && item[pervalue] == value;
                  }),
                  period.field,
                  'amountbackorder',
                  compare
                    ? 'year'
                    : [
                        {
                          name: 'BO Amount',
                          field: 'amountbackorder',
                          type: 'line',
                        },
                        {
                          name: 'Committed Amount',
                          field: 'amountcommitted',
                          type: 'line',
                        },
                        { name: 'Total Amount', field: 'amount' },
                      ],
                  colors[index]
                )
              "
              :option="options()"
            />
          </v-col>
          <v-col md="6">
            <v-subheader>Backordered / Committed</v-subheader>
            <doughnutchart
              :chartData="
                toChartData(
                  'doughnutchart',
                  results.filter((item) => {
                    return item[pervalue] && item[pervalue] == value;
                  }),
                  [
                    {
                      name: 'BO',
                      field: 'amountbackorder',
                      type: 'line',
                    },
                    {
                      name: 'Committed',
                      field: 'amountcommitted',
                      type: 'line',
                    },
                  ],
                  'amountbackorder'
                )
              "
            />
          </v-col>
        </v-row>
      </v-row>

      <v-row>
        <v-col>
          <barchart
            :chartData="
              toChartData(
                'barchart',
                results,
                period.field,
                'amount',
                'billcountry'
              )
            "
            :option="options({ stacked: true })"
          />
        </v-col>
        <v-col>
          <v-subheader>By Country</v-subheader>
          <doughnutchart
            :chartData="
              toChartData('doughnutchart', results, 'billcountry', 'amount')
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <barchart
            :chartData="
              toChartData(
                'barchart',
                results,
                period.field,
                'amount',
                'salesrep'
              )
            "
            :option="options({ stacked: true })"
          />
        </v-col>
        <v-col>
          <v-subheader>By Sales Rep</v-subheader>
          <doughnutchart
            :chartData="
              toChartData('doughnutchart', results, 'salesrep', 'amount')
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <barchart
            :chartData="
              toChartData('barchart', results, period.field, 'amount', 'source')
            "
            :option="options({ stacked: true })"
          />
        </v-col>
        <v-col>
          <v-subheader>By Sources</v-subheader>
          <doughnutchart
            :chartData="
              toChartData('doughnutchart', results, 'source', 'amount')
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <barchart
            :chartData="
              toChartData(
                'barchart',
                results,
                period.field,
                'amount',
                'createdby'
              )
            "
            :option="options({ stacked: true })"
          />
        </v-col>
        <v-col>
          <v-subheader>By Created by</v-subheader>
          <doughnutchart
            :chartData="
              toChartData('doughnutchart', results, 'createdby', 'amount')
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col v-if="items.bytransactiontype">
          <v-subheader>By Transaction Type</v-subheader>
          <barchart
            :chartData="
              toChartData('bar', results, period.field, 'amount', 'recordtype')
            "
            :option="options()"
          />
        </v-col>
        <v-col>
          <v-subheader>Top Customer</v-subheader>
          <v-data-table
            :headers="headers4"
            :items="getCustomers"
            :loading="loading"
            loading-text="Loading... Please wait"
            item-key="_id"
            :footer-props="{ itemsPerPageOptions: rows }"
          >
            <template v-slot:item.amount="{ item }">
              <span>{{ item.amount.toFixed(2) }}</span>
            </template>
            <template v-slot:item.date="{ item }">
              <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
            </template>
            <template v-slot:item.customer="{ item }">
              <router-link
                v-if="item.customer && item.customer._id"
                :to="{
                  path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
                }"
                class="link"
                >{{
                  item.customer.displayname || item.customer.name
                }}</router-link
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="dialog" max-width="900">
      <v-data-table
        v-if="dialog"
        :headers="headersDialog"
        :items="dialgodata"
        :loading="loading"
        :custom-filter="filter"
        :search="search"
        loading-text="Loading... Please wait"
        item-key="name"
      >
        <template v-slot:top>
          <v-container fluid>
            <v-row>
              <v-col>
                <v-text-field
                  dense
                  v-model="search"
                  append-icon="search"
                  label="Search"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <exportData :headers="exportheaders" :items="dialgodata" />
              </v-col>

              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </template>
        <template v-slot:item.customer="{ item }">
          <router-link
            :to="{
              path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
            }"
            class="link"
            >{{ item.customer.displayname || item.customer.name }}</router-link
          >
        </template>
        <template v-slot:item.amount="{ item }">
          <span>
            {{
              new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                style: "currency",
                currency: currency || "PLN",
              }).format(item.amount)
            }}
          </span>
        </template>
        <template v-slot:item.amount2="{ item }">
          <span>
            {{
              new Intl.NumberFormat(currency ? "en-EN" : "pl-PL", {
                style: "currency",
                currency: currency || "PLN",
              }).format(item.amount2)
            }}
          </span>
        </template>
        <template v-slot:item.procent="{ item }">
          <v-chip
            label
            v-if="item.amount2"
            :color="
              (item.amount / item.amount2) * 100 - 100 < 0 ? 'red' : 'green'
            "
            dark
            >{{ Math.round((item.amount / item.amount2) * 100) - 100 }}%
          </v-chip>
        </template>
      </v-data-table>

      <!-- <queryresult
        used="widget"
        queryid="615ffdcd2fba8b4f5c290c0d"
        v-if="dialog"
      /> -->
    </v-dialog>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import piechart from "../../charts/pie";
import barchart from "../../charts/bar";
import linechart from "../../charts/line";
import doughnutchart from "../../charts/doughnut";
import colors from "../../charts/colors";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer", "manufacturergroup"],
  data() {
    return {
      dialgodata: [],
      dialog: false,
      pervalues: [
        { name: "Manufacturer", _id: "manufacturergroup" },
        { name: "Source", _id: "source" },
        { name: "Sales Rep", _id: "salesrep" },
        { name: "Created By", _id: "createdby" },
      ],
      pervalue: "manufacturergroup",
      changed: false,
      active: 0,
      loading: false,
      filters: {},
      datefrom: new Date(new Date().getFullYear() - 2, 0, 2)
        .toISOString()
        .substr(0, 10),
      dateto: new Date().toISOString().substr(0, 10),
      search: null,
      compare: false,
      results: [],
      items: [],
      customers: [],
      initdate: null,
      company: ["maincompany", "ozpartsuklimited"],
      intercompany: false,
      companies: [],
      data: null,
      rows: [5],
      salesreps: [],
      salesrep: null,
      createdbys: [],
      createdby: null,
      sources: [],
      source: [],
      excludemanufacturer: false,
      manufacturers: [],
      manufacturer: [],
      excludecountry: false,
      countries: [],
      country: [],
      show: false,
      currency: false,
      toPrint: "test",
      mode: {
        name: "Invoice Report",
        _id: "sales",
        text: "Sales",
        recordtype: ["invoice", "creditmemo"],
      },
      modes: [
        {
          name: "Invoices",
          _id: "sales",
          text: "Sales",
          recordtype: ["invoice", "creditmemo"],
        },
        {
          name: "Sales Orders",
          _id: "orders",
          text: "Orders",
          recordtype: ["salesorder"],
        },
        {
          name: "Purchase Invoices",
          _id: "bills",
          text: "Purchase",
          recordtype: ["bill"],
        },
        {
          name: "Purchase Orders",
          _id: "purchaseorders",
          text: "Purchase",
          recordtype: ["purchaseorder"],
        },
      ],
      // periods: [
      //   { name: "Weekly", _id: "weekly" },
      //   { name: "Monthly", _id: "monthly" },
      //   { name: "Quarterly", _id: "quarterly" },
      //   { name: "Yearly", _id: "yearly" },
      // ],

      headers: [
        { text: "Item", value: "item" },
        { text: "Manufacturer", value: "manufacturergroup" },
        //{ text: "Makes", value: "makegroups" },
        { text: "Sold", value: "quantity" },
        { text: "Last Sold", value: "date" },
      ],
      headers2: [
        { text: "Period", value: "name" },
        //{ text: "Sold", value: "quantity" },
        { text: "This Year", value: "amount" },
        { text: "Last Year", value: "compareamount" },
        { text: "Value (Count) %", value: "proccompareamount" },
      ],
      headers4: [
        { text: "Customer", value: "customer" },
        { text: "Amount", value: "amount" },
        { text: "Last Invoice", value: "date" },
      ],
      exportheaders: [
        { text: "Customer", value: "customer" },
        { text: "Last Year", value: "amount2" },
        { text: "This Year", value: "amount" },
      ],
      menufrom: false,
      menuto: false,
      customerstype: "",
      customertypes: [
        { _id: "", name: "All" },
        { _id: "b2b", name: "Only B2B" },
        { _id: "b2c", name: "Only B2C" },
      ],
      periods: [
        {
          name: "Weekly",
          field: (val, year) =>
            !this.compare || (year && year != "year")
              ? `${val["year"]}-W${val["week"]}`
              : val["week"],
          _id: "weekly",
        },
        {
          name: "Monthly",
          field: (val, year) =>
            !this.compare || (year && year != "year")
              ? `${val["year"]}-M${val["month"]}`
              : val["month"],
          _id: "monthly",
        },
        {
          name: "Quarterly",
          field: (val, year) =>
            !this.compare || (year && year != "year")
              ? `${val["year"]}-Q${val["quarter"]}`
              : val["quarter"],
          _id: "quarterly",
        },
        {
          name: "Yearly",
          field: "year",
          _id: "yearly",
        },
      ],
      period: {
        name: "Monthly",
        field: (val, year) =>
          !this.compare || (year && year != "year")
            ? `${val["year"]}-M${val["month"]}`
            : val["month"],
        _id: "monthly",
      },
      companiesFilter: [],
      headersDialog: [
        { text: "Customer", value: "customer" },
        { text: "Last Year", value: "amount2" },
        { text: "This Year", value: "amount" },
        { text: "+/- %", value: "procent" },
        // { text: "Year", value: "year" },
        // { text: "Month", value: "month" },
        // { text: "RecordType", value: "parentrecordtype" },
      ],
    };
  },
  async created() {
    if (this.$store.state.user.company)
      this.companiesFilter.push(this.$store.state.user.company);
    if (
      this.$store.state.user.forcompanies &&
      this.$store.state.user.forcompanies.length
    )
      this.companiesFilter.push(...this.$store.state.user.forcompanies);

    this.companiesFilter = [...new Set(this.companiesFilter)];

    this.companies = await service(
      "users",
      {
        action: "find",
        data: [
          {
            recordtype: "company",
            _id: this.companiesFilter.length
              ? { $in: this.companiesFilter }
              : { $exists: true },
          },
        ],
      },
      false,
      true
    );
    this.company = this.companiesFilter;
    this.changed = true;
    // this.countries = await service("countries", {
    //   action: "find",
    //   data: [{}],
    // });
    // this.manufacturers = await service("classification", {
    //   action: "find",
    //   data: [{ recordtype: "manufacturergroup" }],
    // });
    this.salesreps = await service("users", {
      action: "find",
      data: [{ recordtype: "employee" }],
    });
    // this.sources = await service("sources", {
    //   action: "find",
    //   data: [{ recordtype: "source" }],
    // });
    //await this.getData();
  },
  watch: {},
  computed: {
    likesAllFruit() {
      return this.source.length === this.sources.length;
    },
    likesSomeFruit() {
      return this.source.length > 0 && !this.likesAllFruit;
    },
    icon() {
      if (this.likesAllFruit) return "mdi-close-box";
      if (this.likesSomeFruit) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    colors: function () {
      return colors;
    },
    getArrayLoop() {
      if (this.pervalue == "manufacturergroup")
        return this.manufacturer.length
          ? this.excludemanufacturer
            ? this.manufacturers.filter(
                (man) => !this.manufacturer.includes(man)
              )
            : this.manufacturers.filter((man) =>
                this.manufacturer.includes(man)
              )
          : this.manufacturers;
      if (this.pervalue == "billcountry")
        return this.country.length
          ? this.excludecountry
            ? this.countries.filter((man) => !this.country.includes(man))
            : this.countries.filter((man) => this.country.includes(man))
          : this.countries;
      if (this.pervalue == "salesrep")
        return this.salesrep
          ? this.salesreps.filter((man) => this.salesrep.includes(man))
          : this.salesreps;
      if (this.pervalue == "createdby")
        return this.createdby
          ? this.createdbys.filter((man) => this.createdby.includes(man))
          : this.createdbys;
      if (this.pervalue == "source")
        return this.source.length
          ? this.sources.filter((man) => this.source.includes(man))
          : this.sources;
    },
    getCustomers() {
      let tmp = {};
      let items = this.customers.filter((item) => this.itemFilters(item));
      items
        .filter((item) => item.customer && item.customer._id)
        .forEach((item) => {
          tmp[item.customer._id] = tmp[item.customer._id] || {
            amount: 0,
            customer: item.customer,
            date: item.date,
          };
          tmp[item.customer._id].amount += item.amount;
          tmp[item.customer._id].customer = item.customer;
          if (new Date(item.date) > new Date(tmp[item.customer._id].date))
            tmp[item.customer._id].date = item.date;
        });
      items = [];
      return Object.values(tmp).sort(function (a, b) {
        return b.amount - a.amount;
      });
    },
    getItems() {
      let tmp = {};
      let items = this.items.filter((item) => this.itemFilters(item));
      items
        .filter((item) => item.item && item.item._id)
        .forEach((item) => {
          tmp[item.item._id] = tmp[item.item._id] || {
            quantity: 0,
            item: item.item,
            manufacturergroup: item.manufacturergroup,
            makegroups: item.makegroups,
          };
          tmp[item.item._id].quantity += parseInt(item.quantity);
          tmp[item.item._id].manufacturergroup = item.manufacturergroup;
          tmp[item.item._id].makegroups = item.makegroups;
          tmp[item.item._id].date = item.date;
        });
      return Object.values(tmp).sort(function (a, b) {
        return b.quantity - a.quantity;
      });
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.source = [];
        } else {
          this.source = this.sources.slice();
        }
      });
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    getCustomerData(data, period, currentperiod, year, value) {
      let items = data.filter((item) => this.itemFilters(item));
      let filtered = items.filter((l) => {
        if (
          this.mode.recordtype.includes(l.parentrecordtype) &&
          //  l.year == year &&
          (l[period] == currentperiod || period == "year") &&
          (value
            ? !!(l[this.pervalue] == value)
            : true)
        )
          return true;
        else return false;
      });
      filtered = filtered.reduce((t, l) => {
        if (!t[l.customer._id])
          t[l.customer._id] = { customer: l.customer, amount: 0, amount2: 0 };

        if (l.year == year) t[l.customer._id].amount += l.amount;
        if (l.year == year - 1) t[l.customer._id].amount2 += l.amount;
        //console.log(l.year, year - 1, period, currentperiod);
        return t;
      }, {});

      return Object.values(filtered).sort(function (a, b) {
        return b.amount - a.amount;
      });
    },
    getSales(results) {
      let items = results.filter((item) => this.itemFilters(item));
      let tmp = {};
      let periods = [
        { name: "Full Year", period: "year", fn: this.getYear },
        { name: "Quarter", period: "quarter", fn: this.getQuarter },
        { name: "Month", period: "month", fn: this.getMonth },
        { name: "Week", period: "week", fn: this.getWeek },
        { name: "Date to Date", period: "past", fn: () => true },
      ];
      let currentyear = this.getYear(new Date(this.dateto));
      let compareyear = currentyear - 1;
      items.forEach((item) => {
        periods.forEach((period) => {
          let currentperiod = period.fn(new Date(this.dateto));
          tmp[period.period] = tmp[period.period] || {
            name: `${period.name} (${currentperiod})`,
            amount: 0,
            profit: 0,
            compareamount: 0,
            count: 0,
            comparecount: 0,
            compareprofit: 0,
            period: period.period,
            currentperiod: currentperiod,
            currentyear: currentyear,
            compareyear: compareyear,
          };
          if (
            item.year == currentyear &&
            currentperiod == item[period.period]
          ) {
            tmp[period.period].amount += item.amount;
            tmp[period.period].count += item.transaction;
            tmp[period.period].profit += item.profit || 0;
          }
          if (
            item.year == compareyear &&
            (currentperiod == item[period.period] ||
              item[period.period] == compareyear)
          ) {
            tmp[period.period].compareamount += item.amount;
            tmp[period.period].comparecount += item.transaction;
            tmp[period.period].compareprofit += item.profit || 0;
          }
        });
      });
      items = [];
      return Object.values(tmp);
    },
    itemFilters(item) {
      let status = true;
      if (this.mode.recordtype.length) {
        status = this.mode.recordtype.includes(item.parentrecordtype);
      }
      if (this.country.length && status) {
        if (this.excludecountry)
          status = !this.country.includes(item.billcountry);
        else status = this.country.includes(item.billcountry);
      }
      if (this.salesrep && status) {
        status = this.salesrep == item.salesrep;
      }
      if (this.createdby && status) {
        status = this.createdby == item.createdby;
      }
      if (this.source.length && status) {
        status = this.source.includes(item.source);
      }
      if (this.manufacturer.length && status) {
        if (this.excludemanufacturer)
          status = !this.manufacturer.includes(item.manufacturergroup);
        else status = this.manufacturer.includes(item.manufacturergroup);
      }
      return status;
    },
    options(opts = {}) {
      return {
        responsive: true,
        scales: {
          xAxes: [
            {
              // type: "time",
              // time: {
              //   unit: opts.timeunit,

              //   displayFormats: {
              //     day: "MMM D",
              //   },
              // },
              distribution: "linear",
              stacked: opts.stacked || false,
            },
          ],
          yAxes: [
            {
              stacked: opts.stacked || false,
              ticks: {
                beginAtZero: true,
              },
            },
            {
              type: "linear",
              display: opts.yAxisID == "y1" ? true : false,
              position: "right",
              id: "y1",
            },
            {
              type: "linear",
              display: opts.yAxisID == "y2" ? true : false,
              position: "left",
              id: "y2",
            },
          ],
        },
      };
    },

    toChartData(type, results, xaxisfield, yaxisfield, datasetsfield, color) {
      let items = results.filter((item) => this.itemFilters(item));
      let chartData = { labels: [], datasets: [] };
      items.forEach((item) => {
        let xaxis = [];
        if (Array.isArray(xaxisfield)) {
          xaxis = xaxisfield;
        } else {
          xaxis.push({
            name:
              typeof xaxisfield === "function"
                ? xaxisfield(item, datasetsfield)
                : item[xaxisfield]
                ? item[xaxisfield].name || item[xaxisfield]
                : "",
            // field: yaxisfield,
          });
        }

        let datasets = [];
        if (Array.isArray(datasetsfield)) {
          datasets = datasetsfield;
        } else {
          datasets.push({
            name: Array.isArray(item[datasetsfield])
              ? item[datasetsfield]
                  .map((val) => (val ? val.name || val : ""))
                  .toString()
              : item[datasetsfield]
              ? item[datasetsfield]
                ? item[datasetsfield].name || item[datasetsfield].toString()
                : ""
              : "Value",
            field: yaxisfield,
          });
        }
        //if (item[xaxisfield]) {
        xaxis.forEach((x) => {
          datasets.forEach((dataset, index) => {
            chartData.labels[x.name] = 0;
            chartData.datasets[dataset.name] = chartData.datasets[
              dataset.name
            ] || {
              label: dataset.name,
              data: {},
              fill: false,
              tension: 0,
              type: dataset.type,
              yAxisID: dataset.yAxisID,
              order: index,
            };
            chartData.datasets[dataset.name].data[x.name] =
              chartData.datasets[dataset.name].data[x.name] || 0;
            chartData.datasets[dataset.name].data[x.name] +=
              item[x.field || dataset.field] || 0;
          });
        });
        //} // else console.log(item[xaxis], xaxis, items);
      });

      chartData.datasets = Object.values(chartData.datasets);
      chartData.datasets.forEach((dataset, index) => {
        dataset.backgroundColor = ["barchart", "linechart"].includes(type)
          ? color
            ? this.adjust(color, index * -50)
            : colors[index]
          : colors;
        if (["barchart", "linechart"].includes(type))
          dataset.borderColor = dataset.backgroundColor;
        dataset.data = Object.values(
          Object.assign({}, chartData.labels, dataset.data)
        );
      });

      chartData.labels = Object.keys(chartData.labels);
      // .map((label) => {
      //   if (new Date(label) !== "Invalid Date" && !isNaN(new Date(label)))
      //     return new Date(label);
      //   else return label;
      // });
      // chartData.labels = chartData.labels.sort((a, b) => {
      //   return a - b;
      // });
      items = [];
      return chartData;
    },
    adjust(color, amount) {
      return (
        "#" +
        color
          .replace(/^#/, "")
          .replace(/../g, (color) =>
            (
              "0" +
              Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(
                16
              )
            ).substr(-2)
          )
      );
    },

    filter(value, search, item) {
      return filter(value, search, item);
    },
    async getData() {
      this.loading = true;
      this.changed = false;
      let data = await service(
        "report",
        {
          action: "getStatisticsNEW",
          datefrom: this.datefrom,
          dateto: this.dateto,
          customer: this.customer,
          manufacturer: this.manufacturer,
          intercompany: this.intercompany,
          company: this.company,
          customers: this.customerstype,
          salesrep: this.salesrep,
        },
        false,
        false
      );
      //this.data = data.data;
      this.results = data.results;
      this.items = data.items;
      this.customers = data.customers;
      this.sources = data.sources;
      this.countries = data.countries;
      this.salesreps = data.salesreps;
      if (this.salesrep) this.salesrep = this.salesreps[0];
      this.manufacturers = data.manufacturergroups;
      this.createdbys = data.createdby;
      this.loading = false;
    },

    getWeek(date) {
      return new Date(date).getWeek();
    },
    getMonth(date) {
      return new Date(date).getMonth() + 1;
    },
    getDay(date) {
      return new Date(date).getDay();
    },
    getYear(date) {
      return new Date(date).getFullYear();
    },
    getQuarter(date) {
      var month = new Date(date).getMonth() + 1;
      return Math.ceil(month / 3);
    },

    printReport() {
      document.querySelector(".v-application--wrap").style.display = "none";
      document.querySelector("#app").appendChild(this.$refs.reportview);
      window.focus();
      window.print();
      document.querySelector("#reportview").appendChild(this.$refs.reportview);
      document.querySelector(".v-application--wrap").style.display = "";
    },
  },
  components: {
    piechart,
    barchart,
    linechart,
    doughnutchart,
    exportData,
  },
};

Date.prototype.getWeek = function () {
  var onejan = new Date(this.getFullYear(), 0, 4);
  return Math.ceil(((this - onejan) / 86400000 + onejan.getDay()) / 7);
};
</script>
<style scoped>
#printarea {
  background-color: #ffffff !important;
}
#printarea .row {
  page-break-inside: avoid;
}
</style>
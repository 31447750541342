var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"link",attrs:{"to":{
              path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
            }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))])]}},{key:"item.location",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"dense":"","items":_vm.warehouses,"item-text":"name","item-value":"_id","label":"Warehouse","single-line":"","outlined":"","hide-details":""},model:{value:(item.location),callback:function ($$v) {_vm.$set(item, "location", $$v)},expression:"item.location"}})]}},{key:"item.bin",fn:function(ref){
            var item = ref.item;
return [_c('v-text-field',{attrs:{"dense":"","label":"Bin","single-line":"","hide-details":"","outlined":""},on:{"change":function($event){return _vm.update(item)}},model:{value:(item.bin),callback:function ($$v) {_vm.$set(item, "bin", $$v)},expression:"item.bin"}})]}},{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-combobox',{attrs:{"dense":"","items":_vm.filteritems,"label":"Item","outlined":"","return-object":"","item-text":"name","item-value":"_id","hint":"type item name and put enter to find"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.searchRecords.apply(null, arguments)}},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}})],1),_c('v-spacer'),_c('v-spacer')],1)],1)]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticStyle:{"position":"relative","text-align":"right","height":"50px"}},[_c('v-fab-transition',[_c('v-btn',{staticStyle:{"top":"5px","left":"15px"},attrs:{"small":"","absolute":"","top":"","left":"","fab":""},on:{"click":function($event){return _vm.addRow()}}},[_c('v-icon',[_vm._v("add")])],1)],1)],1)]},proxy:true}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-container fluid>
    <v-row>
      <v-container fluid>
        <v-form v-model="valid1" lazy-validation ref="customer">
          <v-row>
            <v-subheader class="mb-3">Main</v-subheader>
            <v-col>
              <v-combobox
                label="Existing / New Customer"
                append-icon="mdi-magnify"
                v-model="record.customer"
                item-text="name"
                item-value="_id"
                :items="customers"
                return-object
                v-on:keyup.enter="getCustomers(record.customer)"
                @change="changeItem(record.customer, 'customer', true)"
                outlined
                class="mb-0"
                :rules="[rules.customer, rules.req]"
                disable-lookup
              ></v-combobox>
              <v-text-field
                autocomplete="new-password"
                prepend-icon="mdi-email"
                label="Contact Email"
                v-model="record.billemail"
                :rules="[rules.req]"
              ></v-text-field>
              <v-text-field
                autocomplete="new-password"
                prepend-icon="mdi-phone"
                label="Primary Phone"
                v-model="record.billphone"
              ></v-text-field>
              <v-radio-group
                v-model="isperson"
                column
                v-if="
                  (record.customer && !record.customer._id) ||
                  (record.customer ? record.customer._id : record.customer) ==
                    'newcustomer'
                "
              >
                <v-radio label="Bussines" :value="false"></v-radio>
                <v-radio label="Individual" :value="true"></v-radio>
              </v-radio-group>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-autocomplete
                v-model="record.billingaddress"
                item-text="name"
                item-value="_id"
                label="Billing Address"
                :items="addressbook"
                @change="changeItem(record.billingaddress, 'billingaddress')"
                v-if="
                  addressbook.length && record.customer._id != 'newcustomer'
                "
                :disabled="
                  record.customer &&
                  record.customer._id &&
                  record.customer._id != 'newcustomer'
                "
                clearable
                outlined
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.name || data.item.addressee
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ data.item.address }} {{ data.item.address2 }}
                      {{ data.item.zip }}
                      {{ data.item.city }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-subheader v-else class="mb-3">Billing</v-subheader>
              <v-form
                v-model="valid2"
                :disabled="
                  !!record.billingaddress ||
                  (record.customer &&
                    record.customer._id &&
                    record.customer._id != 'newcustomer')
                "
                lazy-validation
                ref="billingaddress"
              >
                <v-text-field
                  autocomplete="new-password"
                  v-model="record.billaddressee"
                  prepend-inner-icon="mdi-account"
                  label="Customer name/surname or business name"
                  :rules="[rules.req]"
                ></v-text-field>
                <v-text-field
                  autocomplete="new-password"
                  v-model="record.billaddress"
                  prepend-icon="mdi-map-marker"
                  label="Street address, village/town"
                  :rules="[rules.req]"
                ></v-text-field>

                <v-row>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      autocomplete="new-password"
                      v-model="record.billcity"
                      label="City"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      autocomplete="new-password"
                      v-model="record.billaddress2"
                      label="State / Province"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      autocomplete="new-password"
                      v-model="record.billzip"
                      label="Zip / Postal Code"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <v-select
                      :items="countries"
                      v-model="record.billcountry"
                      item-text="name"
                      item-value="_id"
                      label="Country"
                      :rules="[rules.req]"
                      @change="changeItem(record.billcountry, 'billcountry')"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-autocomplete
                v-model="record.shippingaddress"
                item-text="name"
                item-value="_id"
                label="Shipping Address"
                :items="addressbook"
                @change="changeItem(record.shippingaddress, 'shippingaddress')"
                v-if="
                  addressbook.length && record.customer._id != 'newcustomer'
                "
                clearable
                outlined
                hint="select existing shipping address or clear and leave empty to add new"
                persistent-hint
              >
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title>{{
                      data.item.name || data.item.addressee
                    }}</v-list-item-title>
                    <v-list-item-subtitle
                      >{{ data.item.address }} {{ data.item.address2 }}
                      {{ data.item.zip }}
                      {{ data.item.city }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-subheader v-else class="mb-3">Shipping</v-subheader>

              <v-form
                v-model="valid3"
                v-if="!sameasbilling"
                :disabled="!!record.shippingaddress"
                lazy-validation
                ref="shippingaddress"
              >
                <v-text-field
                  autocomplete="new-password"
                  v-model="record.shipaddressee"
                  prepend-inner-icon="mdi-account"
                  label="Customer name/surname or business name"
                  :rules="[rules.req]"
                ></v-text-field>
                <v-text-field
                  autocomplete="new-password"
                  v-model="record.shipaddress"
                  prepend-icon="mdi-map-marker"
                  label="Street address, village/town"
                  :rules="[rules.req]"
                ></v-text-field>

                <v-row>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      autocomplete="new-password"
                      v-model="record.shipcity"
                      label="City"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      autocomplete="new-password"
                      v-model="record.shipaddress2"
                      label="State / Province"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-0">
                    <v-text-field
                      autocomplete="new-password"
                      v-model="record.shipzip"
                      label="Zip / Postal Code"
                      :rules="[rules.req]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <v-select
                      :items="countries"
                      v-model="record.shipcountry"
                      item-text="name"
                      item-value="_id"
                      label="Country"
                      :rules="[rules.req]"
                      @change="changeItem(record.shipcountry, 'shipcountry')"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-form>
              <v-switch
                v-model="sameasbilling"
                label="Shipping Address same as Billing Address"
                dense
              ></v-switch>
              <v-text-field
                autocomplete="new-password"
                prepend-icon="mdi-phone"
                label="Contact Phone"
                v-model="record.shipphone"
                :rules="[rules.req]"
              ></v-text-field>
              <v-row>
                <v-col>
                  <v-select
                    prepend-icon="mdi-truck-fast"
                    :items="shippingmethods"
                    v-model="record.shippingmethod"
                    item-text="name"
                    item-value="_id"
                    label="Shipping Method"
                    :rules="[rules.req]"
                  ></v-select>
                </v-col>
                <v-col>
                  <v-text-field
                    autocomplete="new-password"
                    v-model="record.shippingcost"
                    label="Shipping Cost"
                    :prefix="record.currency.symbol"
                    @change="changeItem(item, 'shippingcost')"
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-switch
                    v-model="record.dropship"
                    label="Drop Ship"
                    dense
                  ></v-switch
                ></v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-row>
    <v-row>
      <v-subheader>Order Items</v-subheader>
      <v-container fluid class="mb-12">
        <v-data-table
          hide-default-footer
          dense
          :loading="loading"
          :headers="headers"
          :footer-props="{ itemsPerPageOptions: rows }"
          :items="this.record.transactionlines.filter((l) => !l.deleted)"
          class="elevation-0"
          key="_id"
          :item-class="rowClass"
        >
          <template v-slot:item.item="{ item }">
            <v-combobox
              v-model="item.item"
              item-text="name"
              item-value="_id"
              :items="items"
              return-object
              v-on:keyup.enter="getItems(item.item)"
              @change="changeItem(item, 'item')"
              hint="type item name and put enter to find"
              :disabled="loading"
            >
              <template slot="item" slot-scope="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle
                    >{{ data.item.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
          </template>
          <template v-slot:item.pricelevel="{ item }">
            <v-autocomplete
              v-if="!item.kitcomponent"
              v-model="item.pricelevel"
              item-text="name"
              item-value="_id"
              :items="pricelevels"
              @change="changeItem(item, 'pricelevel')"
              :disabled="loading"
            >
            </v-autocomplete>
          </template>
          <template v-slot:item.taxamount="{ item }">
            <span v-if="!item.kitcomponent"
              >{{ record.currency.symbol }}
              {{ item.taxamount.toFixed(2) }}</span
            >
            <!-- <v-autocomplete
              v-if="!item.kitcomponent"
              v-model="item.tax"
              item-text="name"
              item-value="_id"
              :items="taxes"
              @change="changeItem(item, 'tax')"
            >
            </v-autocomplete> -->
          </template>
          <template v-slot:item.description="{ item }">
            <v-text-field
              v-model="item.description"
              :disabled="loading"
            ></v-text-field>
          </template>
          <template v-slot:item.quantity="{ item }">
            <v-text-field
              autocomplete="new-password"
              :disabled="item.kitcomponent || loading"
              suffix="pcs."
              type="number"
              min="0"
              v-model="item.quantity"
              @change="changeItem(item, 'quantity')"
              :hint="
                item.availablememo ? `Available: ${item.availablememo}` : ''
              "
              persistent-hint
            ></v-text-field>
          </template>
          <template v-slot:item.unitprice="{ item }">
            <v-text-field
              autocomplete="new-password"
              v-if="!item.kitcomponent"
              type="number"
              :prefix="record.currency.symbol"
              v-model="item.unitprice"
              @change="changeItem(item, 'unitprice')"
              :hint="item.baseprice ? `RRP: ${item.baseprice}` : ''"
              persistent-hint
              :disabled="loading"
            ></v-text-field>
          </template>
          <template v-slot:item.discountrate="{ item }">
            <!-- <span v-if="!item.kitcomponent"
              >{{ Math.abs(item.discountrate * 100) }}%</span
            > -->
            <v-text-field
              autocomplete="new-password"
              v-if="!item.kitcomponent"
              type="number"
              suffix="%"
              v-model="item.discountrate"
              @change="changeItem(item, 'discountrate2')"
              :disabled="loading"
            ></v-text-field>
          </template>
          <template v-slot:item.price="{ item }">
            <span v-if="!item.kitcomponent"
              >{{ record.currency.symbol }} {{ item.price.toFixed(2) }}</span
            >
          </template>
          <template v-slot:item.amount="{ item }">
            <span v-if="!item.kitcomponent"
              >{{ record.currency.symbol }} {{ item.amount.toFixed(2) }}</span
            >
          </template>
          <template v-slot:item.grossamount="{ item }">
            <span v-if="!item.kitcomponent"
              >{{ record.currency.symbol }}
              {{ item.grossamount.toFixed(2) }}</span
            >
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn class="mt-2" elevation="0" fab small>
              <v-icon dark @click="deleteRow(item)"> mdi-minus-circle </v-icon>
            </v-btn>
          </template>
          <template v-slot:footer>
            <div style="position: relative; text-align: right">
              <v-fab-transition>
                <v-btn
                  small
                  absolute
                  top
                  left
                  fab
                  style="top: -5px; left: -5px"
                  :disabled="loading"
                  @click="addRow()"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-fab-transition>
            </div>
          </template>
        </v-data-table>
        <!-- <v-row>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-col>
            <v-text-field
              autocomplete="new-password"
              type="number"
              label="Total Discount"
              min="0"
              step="1"
              suffix="%"
              v-model="record.discountrate"
              @change="changeItem(record.discountrate, 'discountrate')"
            ></v-text-field>
          </v-col>
        </v-row> -->
      </v-container>
    </v-row>

    <v-row>
      <v-subheader>Summary</v-subheader>
      <v-form v-model="valid4" lazy-validation ref="settings">
        <v-col>
          <v-row>
            <v-col>
              <v-select
                :items="recordtypes"
                v-model="record.recordtype"
                item-text="name"
                item-value="_id"
                label="Order Type"
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="sources"
                v-model="source"
                item-text="name"
                item-value="_id"
                label="Source"
                outlined
                dense
                :rules="[rules.req]"
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-text-field
            autocomplete="new-password"
            prepend-icon="mdi-numeric"
            label="Ref. Number (Optional)"
            v-model="record.referencenumber"
            outlined
            dense
          ></v-text-field>

          <v-row>
            <v-col>
              <v-select
                disabled
                :items="companies"
                v-model="record.company"
                item-text="name"
                item-value="_id"
                prepend-icon="mdi-domain"
                label="Company"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                disabled
                :items="locations"
                v-model="record.location"
                item-text="name"
                item-value="_id"
                prepend-icon="mdi-warehouse"
                label="Warehouse"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="currencies"
                v-model="record.currency"
                item-text="name"
                item-value="_id"
                return-object
                @change="changeItem(record.customer, 'currency', true)"
                prepend-icon="mdi-currency-usd"
                label="Currency"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-autocomplete
                label="Tax"
                v-model="record.shippingtax"
                item-text="name"
                item-value="_id"
                :items="taxes"
                @change="changeItem(record.shippingtax, 'shippingtax')"
                outlined
                dense
                hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                :items="paymentmethods"
                v-model="record.paymentmethod"
                item-text="name"
                item-value="_id"
                return-object
                @change="changeItem(record.customer, 'paymentmethod', true)"
                prepend-icon="mdi-cash-multiple"
                label="Payment Method"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="terms"
                v-model="record.terms"
                item-text="name"
                item-value="_id"
                return-object
                @change="changeItem(record.customer, 'terms', true)"
                prepend-icon="mdi-clipboard-text-clock-outline"
                label="Terms"
                outlined
                dense
                hide-details
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-form>
      <v-divider vertical></v-divider>
      <v-col>
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Items Amount:</v-list-item-subtitle>
              <v-list-item-title
                >{{ record.currency.symbol }}
                {{ (record.itemsamount || 0).toFixed(2) }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Shipping Cost:</v-list-item-subtitle>
              <v-list-item-title
                >{{ record.currency.symbol }}
                {{ (record.shippingamount || 0).toFixed(2) }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Net. Amount:</v-list-item-subtitle>
              <v-list-item-title
                >{{ record.currency.symbol }}
                {{ (record.amount || 0).toFixed(2) }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Tax Amount:</v-list-item-subtitle>
              <v-list-item-title
                >{{ record.currency.symbol }}
                {{ (record.taxamount || 0).toFixed(2) }}</v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>Gross Amount:</v-list-item-subtitle>
              <v-list-item-title>
                <b
                  >{{ record.currency.symbol }}
                  {{ (record.grossamount || 0).toFixed(2) }}</b
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-btn @click="createRecord()" block tile color="primary">Save</v-btn>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col>
        <v-textarea
          v-model="record.memo"
          label="Inner Notes (Optional)"
          outlined
          dense
          height="120px"
        ></v-textarea>
        <v-textarea
          autocomplete="new-password"
          prepend-icon="mdi-message"
          label="Notes on the invoice (Optional - visible on PDF)"
          v-model="record.billinginstructions"
          outlined
          dense
          height="80px"
        ></v-textarea>
        <v-row>
          <v-col>
            <v-select
              :items="focreasons"
              v-model="record.focreason"
              item-text="name"
              item-value="_id"
              return-object
              prepend-icon="mdi-currency-usd-off"
              label="FOC Reason"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col>
            <v-textarea
              autocomplete="new-password"
              label="Free Of Charge - Details"
              v-model="record.focreasondetails"
              outlined
              dense
              height="30px"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              @click="createRecord('save', true)"
              block
              tile
              color="primary"
              >Save & Print</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              @click="createRecord('trigger_emailsalesorder')"
              block
              tile
              color="primary"
              >Save & Email</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="msg.show"
      :color="msg.type"
      :timeout="6000"
      bottom
      center
      >{{ msg.message }}</v-snackbar
    >
    <v-overlay :value="loadingfull">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import recalcRecord from "../../../common/validations/recalcRecord";
import saveRecord from "../../../common/methods/saveRecord";
export default {
  data() {
    return {
      rows: [-1],
      valid1: true,
      valid2: true,
      valid3: true,
      valid4: true,
      loading: false,
      loadingfull: false,
      isperson: true,
      msg: false,
      step: 1,
      currencies: [],
      focreasons: [],
      forms: {
        customer: { warning: false, reqfields: [] },
        addresses: { warning: false, reqfields: [] },
      },
      customers: [{ _id: "newcustomer", name: "NEW CUSTOMER" }],
      items: [],
      pricelevels: [],
      terms: [],
      paymentmethods: [],
      taxes: [],
      sameasbilling: true,
      countries: [],

      shippingmethods: [],
      locations: [{ _id: "61e14c6ae4a35a668517a8fe", name: "UK" }],
      source: "ozpartsuk",
      sources: [
        { _id: "ozpartsuk", name: "Ozparts.uk" },
        // { _id: "pedderscouk", name: "Pedders.co.uk" },
      ],
      companies: [{ _id: "ozpartsuklimited", name: "Ozparts UK Limited" }],
      recordtypes: [
        { _id: "salesorder", name: "Sales Order" },
        { _id: "estimate", name: "Estimate" },
      ],
      record: {
        recordtype: "salesorder",
        collection: "transactions",
        created: "new",
        company: "ozpartsuklimited",
        location: this.$store.state.user.location,
        source: "ozpartsuk",
        terms: "prepaid",
        paymentmethod: "5fd228b3167228cea0e64a82",
        shippingmethod: {
          _id: "61e671042c96214290aeced6",
          name: "DPD Next Day",
        },
        shippingcost: 0,
        memo: "",
        salesrep: this.$store.state.user._id,
        shippingtax: "5e22ee60f4e3f79cece0f938",
        customer: { _id: "newcustomer", name: "NEW CUSTOMER" },
        isperson: this.isperson,
        billcountry: { _id: "GB", name: "United Kingdom" },
        currency: { _id: "gbp", name: "GBP", symbol: "£" },
        tax: "5e22ee60f4e3f79cece0f938",
        tool: "simpleorder",
        discountrate: 0,
        billphone: "",
        transactionlines: [],
      },
      newline: {
        created: "new",
        item: null,
        description: "",
        quantity: 1,
        price: 0,
        discountrate: 0,
        amount: 0,
        tax: "5e22ee60f4e3f79cece0f938",
        taxamount: 0,
        grossamount: 0,
        recordtype: "itemline",
      },
      headers: [
        { text: "Item", value: "item", sortable: false },
        { text: "Description", value: "description", sortable: false },
        { text: "Qty.", value: "quantity", sortable: false, width: "150" },
        { text: "Net. Price", value: "unitprice", sortable: false },
        { text: "Price Level", value: "pricelevel", sortable: false },
        {
          text: "% Disc.",
          value: "discountrate",
          sortable: false,
          width: "100",
        },
        { text: "Price", value: "price" },
        { text: "Amount", value: "amount" },
        { text: "Tax", value: "taxamount", sortable: false, width: "100" },
        { text: "Gross", value: "grossamount", sortable: false },
        { text: "Action", value: "action", sortable: false },
      ],
      rules: {
        req: (v) => !!v || "Field is required",
        customer: (v) =>
          !!v._id ||
          "Type name or email and put enter to find an existing customer or set a NEW CUSTOMER to create a new one",
        min10: (v) =>
          (v && v.length <= 10) || "Name must be less than 10 characters",
      },
      addressbook: [],
    };
  },
  watch: {
    async record() {
      //this.record.currency = { _id: "gbp", name: "GBP", symbol: "£" };
      this.record.location = "61e14c6ae4a35a668517a8fe";
      this.record.company = "ozpartsuklimited";
      //this.record.shippingtax = "5e22ee60f4e3f79cece0f938";
      if (this.record.customer && this.record.customer._id == "newcustomer")
        this.record.isperson = this.isperson;

      // this.sources = await service("sources", {
      //   action: "find",
      //   data: [{ recordtype: "source", forlocations: this.record.location }],
      // }).then((response) => {
      //   response.forEach((v) => {
      //     v.name = v.displayname || v.name;
      //   });
      //   return response;
      // });
    },
    source(v) {
      this.record.source = v;
    },
    async "record.customer"(value) {
      if (value && value._id) {
        this.addressbook = await service("addresses", {
          action: "find",
          data: [{ recordtype: "address", user: value._id }],
          limit: 500,
        });
      }
    },
    sameasbilling(value) {
      if (this.sameasbilling) {
        this.record.shippingaddress = this.record.billingaddress;
        this.changeItem(this.record.shippingaddress, "shippingaddress");
      } else {
        this.record.shippingaddress = null;
      }
    },
    "record.billphone"(value) {
      if (
        !this.record.shippingaddress ||
        (this.record.shippingaddress && !this.record.shippingaddress.shipphone)
      )
        this.record.shipphone = value;
    },
    "record.billingaddress"(value) {
      if (
        (this.record.billingaddress &&
          this.record.shippingaddress &&
          this.record.billingaddress._id != this.record.shippingaddress._id) ||
        (!this.record.billingaddress && this.record.shippingaddress) ||
        (this.record.billingaddress && !this.record.shippingaddress)
      )
        this.sameasbilling = false;
      if (
        this.record.billingaddress &&
        this.record.shippingaddress &&
        this.record.billingaddress._id == this.record.shippingaddress._id
      )
        this.sameasbilling = true;
    },
    "record.shippingaddress"(value) {
      if (
        (this.record.billingaddress &&
          this.record.shippingaddress &&
          this.record.billingaddress._id != this.record.shippingaddress._id) ||
        (!this.record.billingaddress && this.record.shippingaddress) ||
        (this.record.billingaddress && !this.record.shippingaddress)
      )
        this.sameasbilling = false;
      if (
        this.record.billingaddress &&
        this.record.shippingaddress &&
        this.record.billingaddress._id == this.record.shippingaddress._id
      )
        this.sameasbilling = true;
    },
  },
  async created() {
    for (let [key, value] of Object.entries(this.$route.params)) {
      if (key == "customer") {
        this.customers.push({ _id: value._id, name: value.name });
        this.record[key] = { _id: value._id, name: value.name };
        await this.changeItem(this.record[key], "customer", true);
      } else {
        this.record[key] = value;
      }
    }
    this.record.transactionlines.push({ ...this.newline });
    //Get pricelevels
    this.countries = await service("countries", {
      action: "find",
      data: [{}],
      limit: 250,
    });
    this.currencies = await service("accounting", {
      action: "find",
      data: [{ recordtype: "currency" }],
    });
    this.focreasons = await service("lists", {
      action: "find",
      data: [{ recordtype: "focreason" }],
    });
    this.shippingmethods = await service("items", {
      action: "find",
      data: [
        {
          recordtype: "shippingitem",
          hidden: { $ne: true },
          $or: [
            { forlocations: "61e14c6ae4a35a668517a8fe" },
            { forlocations: [] },
            { forlocations: { $exists: false } },
          ],
        },
      ],
    });
    this.pricelevels = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "pricelevel",
          $or: [
            { forlocations: "61e14c6ae4a35a668517a8fe" },
            { _id: { $in: ["customprice", "baseprice"] } },
          ],
        },
      ],
    }).then((response) => {
      response.forEach((v) => {
        v.name = v.displayname || v.name;
      });
      return response;
    });
    this.terms = await service("accounting", {
      action: "find",
      data: [{ recordtype: "term" }],
    }).then((response) => {
      response.forEach((v) => {
        v.name = v.displayname || v.name;
      });
      return response;
    });
    this.paymentmethods = await service("accounting", {
      action: "find",
      data: [{ recordtype: "paymentmethod" }],
    });
    this.taxes = await service("accounting", {
      action: "find",
      data: [{ recordtype: "tax" }],
    }).then((response) => {
      response.forEach((v) => {
        v.name = v.displayname || v.name;
      });
      return response;
    });
    // this.sources = await service("sources", {
    //   action: "find",
    //   data: [{ recordtype: "source", forlocations: this.record.location }],
    // }).then((response) => {
    //   response.forEach((v) => {
    //     v.name = v.displayname || v.name;
    //   });
    //   return response;
    // });
  },
  methods: {
    rowClass(item) {
      if (item.kitcomponent) {
        return "kitcomponent";
      }
    },
    addRow() {
      this.record.transactionlines.push({ ...this.newline });
    },
    async deleteRow(item) {
      item.deleted = true;
      await this.changeItem(item, "deleted");
      this.record.transactionlines = this.record.transactionlines.filter(
        (line) => !line.deleted || line.created != "new"
      );
    },
    nextStep(step, form) {
      if (!this.$refs[form].validate()) {
        this.forms[form].warning = true;
        this.forms[form].reqfields = [];
        this.$refs[form].inputs.forEach((input) => {
          if (!input.valid) this.forms[form].reqfields.push(input.label);
        });
      } else {
        this.forms[form].warning = false;
        this.forms[form].reqfields = [];
      }
      this.step = step;
    },
    async changeItem(item, triger, object) {
      try {
        this.items = [];
        this.loading = true;
        if (triger == "item") console.log(item.item);
        if (
          item &&
          (object ? !!item._id : true) &&
          (triger == "item"
            ? item[triger]
              ? item[triger]._id && item[triger]._id.length == 24
              : false
            : true)
        ) {
          let newRecord = JSON.parse(JSON.stringify(this.record));
          newRecord.transactionlines = newRecord.transactionlines.filter(
            (l) => l.item || l.created != "new"
          );
          newRecord.transactionlines.forEach((l) => {
            l.discountrate = Math.abs(l.discountrate / 100) * -1;
            l.tax = newRecord.shippingtax;
          });
          newRecord.discountrate = Math.abs(newRecord.discountrate / 100) * -1;
          if (!newRecord.customer || !newRecord.customer._id)
            newRecord.customer = { _id: "newcustomer", name: "NEW CUSTOMER" };
          if (["unitprice", "item", "quantity"].includes(triger)) {
            item.tax = newRecord.shippingtax;
            let newline = await recalcRecord(item, newRecord, triger);
            let index = newRecord.transactionlines.findIndex(
              (line) => line._id == newline._id
            );

            if (index >= 0) newRecord.transactionlines[index] = newline;
            else if (newRecord.transactionlines.length - 1 >= 0)
              newRecord.transactionlines[
                newRecord.transactionlines.length - 1
              ] = newline;

            this.record = await recalcRecord(
              newRecord,
              false,
              "transactionlines"
            );
          } else this.record = await recalcRecord(newRecord, false, triger);
          if (triger == "customer") {
            this.record = await recalcRecord(this.record, false, "init");
            this.record.billemail = this.record.customer.email;
            this.record.billphone = this.record.customer.phone;
          }
          this.record.transactionlines.forEach(
            (l) => (l.discountrate = Math.abs(l.discountrate * 100))
          );
          if (!this.record.transactionlines.length)
            this.record.transactionlines.push({ ...this.newline });
          this.record.discountrate = Math.abs(this.record.discountrate * 100);
        } else {
          if (triger == "customer") this.record.billaddressee = item;
        }
      } catch (error) {
        this.msg = { message: `${error.message}`, show: true, type: "error" };
      }
      this.loading = false;
    },
    async getItems(item) {
      if (item._id) item = item.name;
      this.items = await service("items", {
        action: "find",
        data: [
          {
            recordtype: { $in: ["inventoryitem", "kititem", "service"] },
            inactive: { $ne: true },
            $or: [
              { name: { $regex: `.*${item}.*`, $options: "i" } },
              { displayname: { $regex: `.*${item}.*`, $options: "i" } },
            ],
          },
        ],
        limit: 100,
      }).then((response) => {
        response.forEach((v) => {
          v.name = v.displayname || v.name;
        });
        return response;
      });
    },
    async getCustomers(customer) {
      if (!customer._id) {
        this.customers = await service("users", {
          action: "find",
          data: [
            {
              recordtype: { $in: ["customer"] },
              $or: [
                { name: { $regex: `.*${customer}.*`, $options: "i" } },
                { displayname: { $regex: `.*${customer}.*`, $options: "i" } },
                { email: { $regex: `.*${customer}.*`, $options: "i" } },
              ],
            },
          ],
          limit: 100,
        }).then((response) => {
          response.unshift({
            _id: "newcustomer",
            name: "NEW CUSTOMER",
            displayname: "NEW CUSTOMER",
          });
          response.forEach((v) => {
            v.name = v.displayname || v.name;
          });

          return response;
        });
      }
    },
    async createRecord(triger = "save", print) {
      try {
        this.loadingfull = true;
        this.$refs["customer"].validate();
        this.$refs["billingaddress"].validate();
        this.$refs["shippingaddress"]
          ? this.$refs["shippingaddress"].validate()
          : true;
        this.$refs["settings"].validate();

        this.$refs["customer"].inputs.forEach((input) => {
          if (!input.valid)
            this.msg = {
              message: `${input.label} is required`,
              show: true,
              type: "error",
            };
        });
        this.$refs["billingaddress"].inputs.forEach((input) => {
          if (!input.valid)
            this.msg = {
              message: `Billing Address, ${input.label} is required`,
              show: true,
              type: "error",
            };
        });
        if (this.$refs["shippingaddress"])
          this.$refs["shippingaddress"].inputs.forEach((input) => {
            if (!input.valid)
              this.msg = {
                message: `Shipping Address, ${input.label} is required`,
                show: true,
                type: "error",
              };
          });
        if (this.$refs["settings"])
          this.$refs["settings"].inputs.forEach((input) => {
            if (!input.valid)
              this.msg = {
                message: `Summary, ${input.label} is required`,
                show: true,
                type: "error",
              };
          });
        if (
          this.$refs["customer"].validate() &&
          this.$refs["billingaddress"].validate() &&
          (this.$refs["shippingaddress"]
            ? this.$refs["shippingaddress"].validate()
            : true) &&
          this.$refs["settings"].validate()
        ) {
          if (!this.record.customer || !this.record.customer._id)
            this.record.customer = { _id: "newcustomer", name: "NEW CUSTOMER" };
          this.record.transactionlines.forEach((l) => {
            l.discountrate = Math.abs(l.discountrate / 100) * -1;
          });
          this.record.discountrate =
            Math.abs(this.record.discountrate / 100) * -1;
          this.record = await recalcRecord(this.record, false, "recalc");
          await saveRecord(this.record, false, triger, "transactions");
          if (print) await this.pdf();
          this.$router.push({
            path: `/${this.record.collection}/${this.record.recordtype}/view/${this.record._id}`,
          });
        }
      } catch (error) {
        this.record.transactionlines.forEach(
          (l) => (l.discountrate = Math.abs(l.discountrate * 100))
        );
        this.record.discountrate = Math.abs(this.record.discountrate * 100);
        this.msg = { message: `${error.message}`, show: true, type: "error" };
      }
      this.loadingfull = false;
    },
    pdf(pdfform = "") {
      if (pdfform) pdfform = `/?pdfform=${pdfform}`;
      else pdfform = "";
      let pdf = `http://localhost:8080/api/pdf/${this.record.collection}/${
        this.record._id
      }/${(this.record.name || this.record.recordtype)
        .replaceAll("#", "%23")
        .replaceAll("/", "%2F")}${pdfform}`;
      window.open(pdf, "_blank");
    },
  },
};
</script>
<style scoped>
.kitcomponent {
  background: #ececec;
}

.kitcomponent .v-input {
  padding: 0px;
}

tr.kitcomponent .v-text-field__details {
  display: none;
}
</style>
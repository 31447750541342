


<template>
  <v-row v-if="record.customer && record.company && record.created != 'new'">
    <v-col>
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-select
              v-model="carrier"
              :items="carrieres"
              item-text="name"
              item-value="_id"
              label="Carrier"
              placeholder
              outlined
              return-object
              hide-details
              dense
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-select
              v-model="method"
              :items="methods"
              item-text="name"
              item-value="_id"
              label="Method"
              placeholder
              outlined
              return-object
              hide-details
              dense
            ></v-select>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              dense
              outlined
              type="number"
              label="CBM"
              hide-details
              v-model="cbm"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-action>
            <v-icon color="primary">mdi-weight-kilogram</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-text-field
              dense
              outlined
              type="number"
              hide-details
              label="Weight"
              v-model="weight"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <v-list two-line>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              dense
              outlined
              type="number"
              hide-details
              label="Length"
              v-model="length"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              dense
              outlined
              type="number"
              label="Width"
              hide-details
              v-model="width"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              dense
              outlined
              type="number"
              hide-details
              label="Height"
              v-model="height"
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-col>
    <v-col>
      <v-list two-line>
        <v-list-item>
          <v-list-item-action>
            <v-icon color="primary">mdi-package-variant-closed</v-icon>
          </v-list-item-action>

          <v-list-item-content>
            <v-text-field
              dense
              outlined
              type="number"
              label="Pallet"
              v-model="palletes"
              hide-details
            ></v-text-field>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <writeemail
        :record="recordEmail"
        :emailtemplate="emailtemplate"
        label="Compose Request Email"
        @emit="catchEmit($event)"
      ></writeemail>
    </v-col>
  </v-row>
  <v-alert v-else dense outlined type="error">
    Please fill up customer and company fields and save
  </v-alert>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import writeemail from "../../inbox/write.vue";
export default {
  props: ["msg", "record", "selected"],
  data() {
    return {
      carrier: null,
      carrieres: [
        {
          _id: "kn",
          name: "Kuehne Nagel",
          template: "",
        },
        {
          _id: "rohlig",
          name: "Rohlig",
          template: "",
        },
        {
          _id: "hellmann",
          name: "Hellmann",
          template: "",
        },
        {
          _id: "pfc",
          name: "PFC",
          template: "",
        },
        {
          _id: "mainfreight",
          name: "Mainfreight",
          template: "",
        },
        {
          _id: "eliteuk",
          name: "Elite UK",
          template: "",
        },
        {
          _id: "efsuk",
          name: "EFS UK",
          template: "",
        },
        {
          _id: "activo",
          name: "activo",
          template: "",
        },
      ],
      method: null,
      methods: [
        {
          _id: "Sea",
          emailtext: "morskiego",
          name: "Morski",
          email: {
            hellmann: "Adam.Pietrzak@hellmann.com",
            kn: "oktawia.gogolewska@kuehne-nagel.com",
            pfc: "pburzynski@pfc24.pl",
            mainfreight: "pawel.rutkowski@mainfreight.com",
            rohlig: "mtrzcinska@suus.com",
            eliteuk: "sales@elite-wwl.com",
            efsuk: "chris@efsau.com.au, ian@efsau.com.au",
            activio: "esielska@activologistics.pl",
          },
        },
        {
          _id: "Train",
          emailtext: "kolejowego",
          name: "Kolejowy",
          email: {
            hellmann: "Marta.Danielewska-Palma@hellmann.com",
            kn: "oktawia.gogolewska@kuehne-nagel.com",
            pfc: "pburzynski@pfc24.pl",
            mainfreight: "pawel.rutkowski@mainfreight.com",
            rohlig: "mtrzcinska@suus.com",
            eliteuk: "sales@elite-wwl.com",
            efsuk: "chris@efsau.com.au, ian@efsau.com.au",
            activio: "lmatuszewska@activologistics.pl",
          },
        },
        {
          _id: "Air",
          emailtext: "lotniczego",
          name: "Lotniczy",
          email: {
            hellmann: "Izabela.Oleksiak@hellmann.com",
            kn: "oktawia.gogolewska@kuehne-nagel.com",
            pfc: "pburzynski@pfc24.pl, pkowalski@pfc24.pl",
            mainfreight: "pawel.rutkowski@mainfreight.com",
            rohlig: "mtrzcinska@suus.com",
            eliteuk: "sales@elite-wwl.com",
            efsuk: "chris@efsau.com.au, ian@efsau.com.au",
            activio: "rodolinska@activologistics.pl",
          },
        },
        // {
        //   _id: "Road",
        //   email: "drogowego",
        //   name: "Drogowy",
        //   email: {
        //     hellman: "Adam.Pietrzak@hellmann.com",
        //     kn: "oktawia.gogolewska@kuehne-nagel.com",
        //     pfc: "pburzynski@pfc24.pl",
        //     mainfreight: "pawel.rutkowski@mainfreight.com",
        //     rohling: "mtrzcinska@suus.com",
        //     eliteuk: "sales@elite-wwl.com",
        //     afsuk: "chris@efsau.com.au, ian@efsau.com.au",
        //     activio: "esielska@activologistics.pl",
        //   },
        // },
      ],
      weight: 0,
      width: 0,
      height: 0,
      length: 0,
      cbm: 0,
      palletes: 1,
    };
  },
  watch: {},
  created() {},
  computed: {
    recordEmail() {
      return {
        // recordtype: "transactionrequest",
        collection: "deliveries",
        name: "Wycena transportu",
        _id: this.record._id,
        customer: {
          email:
            this.method && this.method.email
              ? this.method.email[this.carrier._id]
              : "",
        },
      };
    },
    emailtemplate() {
      let subject = `Prośba o wycenę dla importu do Ozparts (${this.record.name})`;
      let text = `
        <p>Dzień dobry,</p>
        <p>proszę o wycenę importu ${
          this.method ? this.method.emailtext : ""
        }:</p>

        <ul>
            <li>Towar neutralny</li>
            <li> Paleta - ${this.length}cm x ${this.width}cm x ${
        this.height
      }cm</li>
            <li> Waga brutto total: ${this.weight} kg</li>
            <li>CBM: ${this.cbm}</li>
        </ul>

        <p>Warunki transportu EXW.</p>

        <p><b>Odbiór:</b></p>
        <p>
        ${
          this.record.pickupaddress
            ? this.record.pickupaddress.addressee || ""
            : ""
        }<br/>
        ${
          this.record.pickupaddress
            ? this.record.pickupaddress.address || ""
            : ""
        },
         ${
           this.record.pickupaddress
             ? this.record.pickupaddress.address2 || ""
             : ""
         }
        <br/>
   
        ${
          this.record.pickupaddress ? this.record.pickupaddress.city || "" : ""
        } ${
        this.record.pickupaddress ? this.record.pickupaddress.zip || "" : ""
      }<br/>
        ${
          this.record.pickupaddress && this.record.pickupaddress.country
            ? this.record.pickupaddress.country.name || ""
            : ""
        }<br/>
        ${
          this.record.pickupaddress ? this.record.pickupaddress.phone || "" : ""
        }
        </p>

        <p><b>Dostawa:</b></p>
        <p>
        ${
          this.record.pickupaddress
            ? this.record.deliverytoaddress.addressee || ""
            : ""
        }<br/>
        ${
          this.record.pickupaddress
            ? this.record.deliverytoaddress.address || ""
            : ""
        },
        ${
          this.record.pickupaddress
            ? this.record.deliverytoaddress.address2 || ""
            : ""
        }
        <br/>

        ${
          this.record.pickupaddress
            ? this.record.deliverytoaddress.city || ""
            : ""
        } ${
        this.record.deliverytoaddress
          ? this.record.deliverytoaddress.zip || ""
          : ""
      }<br/>
        ${
          this.record.pickupaddress && this.record.deliverytoaddress.country
            ? this.record.deliverytoaddress.country.name || ""
            : ""
        }<br/>
        ${
          this.record.pickupaddress
            ? this.record.deliverytoaddress.phone || ""
            : ""
        }
        </p>


        <p>Towar gotowy do odbioru.</p>
        <p>Z góry dziękuje.</p>

        `;

      let signature = `<br/><br/>__
<table>
<tbody><tr style="font-weight:700"><td colspan="2" style="font-size:14px;padding-bottom:5px">Pozdrawiam / Best Regards,</td></tr><tr style="font-size:16px;font-weight:700"><td colspan="2">${
        this.$store.state.user.name
      }</td></tr><tr style="color:rgb(102,102,102)"><td colspan="2">${
        this.$store.state.user.jobtitle
      }</td></tr><tr><td colspan="2" style="padding:10px 0px"><a href="https://ozparts.eu/" target="_blank"><img src="https://ozparts.eu/signature-2023/15th.png" title="Ozparts" alt="Ozparts"></a></td></tr><tr><td><b>E-mail:</b>&nbsp;<a href="mailto:${
        this.$store.state.user.email
      }" style="color:rgb(0,0,0)" target="_blank">${
        this.$store.state.user.email
      }</a></td><td><b>Ozparts PL sp. z o.o.</b></td></tr><tr><td><b>Mob:&nbsp;</b>${
        this.$store.state.user.phone || ""
      }</td><td>Słowackiego 32/5</td></tr><tr><td><b>Web:</b>&nbsp;<a href="https://ozparts.eu/" style="color:rgb(0,0,0)" target="_blank" >ozparts.eu</a></td><td>87-100 Toruń</td></tr>
  
  <tr><td colspan="2"><a href="https://ozparts.eu/#brands" target="_blank" ><img src="https://ozparts.eu/signature-2023/sig500eu.png" title="Our brands" alt="Our brands" style="max-width:500px"></a></td></tr>
  <tr style="font-size:10px"><td colspan="2"><div style="width:500px"><b>Ozparts PL sp. z o.o.</b>&nbsp;is registered in Toruń on the street Słowackiego 32/5, 87-100 Toruń, KRS 0000984204, company records in Toruń District Court, REGON 340473620, NIP 9562230129, Share Capital 30.000 PLN.&nbsp;<b>Warehouse address:</b>&nbsp;Mazowiecka 46-48, 87-100 Toruń. Your data is processed by us in compliance with RODO (GDPR). You can find the link to our Privacy Policy&nbsp;<a href="https://ozparts.eu/privacy_policy_5" style="color:rgb(0,0,0)" target="_blank" >here</a>.</div><div><br></div></td></tr></tbody>
</table>

            
          `;
      return {
        receiverto:
          this.method && this.method.email
            ? this.method.email[this.carrier._id]
            : "",
        subject: subject,
        text: text + signature,
        cc: [],
      };
    },
  },
  methods: {
    async catchEmit(e) {
      this.record.carrieroffers.push({
        create: "new",
        shippingcarriertext: this.carrier.name,
        shippingcarrierservicetext: this.method._id,
        cbm: this.cbm,
        weight: this.weight,
        palletes: this.palletes,
        sent: true,
      });
    },
  },
  components: {
    writeemail,
  },
};
</script>
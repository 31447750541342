function filter(value, search, item) {
    //gdy jest groupby
    if (!value && item.groupby) {
        value = item[item.groupby.split(".")[0]];
    }
    if (value != null && search != null) {
        if (value.name || value.displayname)
            return value.name.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
        if (typeof value === "string")
            return value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1;
        if (Array.isArray(value)) {
            let test = false;
            value.forEach(v => {
                test = !test ? filter(v, search, item) : true;
            });
            return test;
        }
    }
}
export default filter;
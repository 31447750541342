<template>
  <v-menu bottom right>
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" large>
        <v-icon large>mdi-file-download</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-subheader>Export</v-subheader>
      <v-list-item @click="exportData('csv')">
        <v-list-item-icon>
          <v-icon>mdi-file-delimited</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          >CSV - Export as comma-separated values</v-list-item-title
        >
      </v-list-item>

      <v-list-item @click="exportData('xls')">
        <v-list-item-icon>
          <v-icon>mdi-file-table-box-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          >XLS - Export as Microsoft Excel format</v-list-item-title
        >
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import exportFromJSON from "export-from-json";
import getFields from "../../common/methods/getFields";
export default {
  props: ["items", "headers", "name"],
  data() {
    return {
      loading: false,
      fields: {},
    };
  },
  methods: {
    async exportData(exportType) {
      this.loading = true;
      this.fields = await getFields(this);
      //console.log(this.fields)
      let data = [];
      //console.log(this.items,this.headers)
      this.items.forEach((item) => {
        let row = {};
        //if (item._id) row["id"] = item._id;
        this.headers.forEach((header) => {
          if (header.value) {
            row[header.text] = this.getValue(
              item[header.value],
              this.fields[header.field || header.value],
              header.function
            );
          }
        });
        data.push(row);
      });
      const fileName = `3C-${this.name || "export"}`;
      await exportFromJSON({ data, fileName, exportType });
      this.loading = false;
    },
    getValue(value, field = null, fn) {
      if (value) {
        //console.log(value,typeof value)
        if (value.name || value.displayname)
          return value.displayname || value.name;
        if (
          value instanceof Date ||
          (field && field.setup.type == "field-date")
        ) {
          if (!fn)
            return value
              ? new Date(`${value}`).toISOString().substr(0, 10)
              : "";
          else return value;
        }
        if (
          typeof value === "boolean" ||
          (field && field.setup.type == "field-date")
        )
          return value ? "YES" : "NO";
        if (
          typeof value === "number" ||
          (field &&
            ["field-currency", "field-percent"].includes(field.setup.type) &&
            !isNaN(value))
        )
          return parseFloat(value)
            .toFixed(
              (field && field.setup.precision) ||
                (field && field.setup.type == "field-percent" ? 3 : 2)
            )
            .replace(".", ",");

        if (Array.isArray(value)) {
          let newvalue = "";
          value.forEach((v, i) => {
            v = this.getValue(v);
            newvalue += i ? `, ${v}` : v;
          });
          return newvalue;
        }
        if (typeof value == "string") return value.replace(/\n/g, " ");
        else return value;
      }
      return "";
    },
  },
};
</script>

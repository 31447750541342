<template>
  <div>
    <v-tabs v-model="tab" background-color="primary" dark>
      <v-tab> Stock Check Summary </v-tab>
      <v-tab> Unrecognised Items </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-text-field
                dense
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <exportData :headers="headersExport" :items="items" />
            </v-col>
            <v-col>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="From:"
                    placeholder
                    v-model="date"
                    append-icon="event"
                    readonly
                    v-on="on"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <v-select
                :items="locations"
                v-model="location"
                label="Warehouse"
                item-text="name"
                item-value="_id"
                outlined
                clearable
                hide-details
                class="mt-2 mb-2"
                dense
              ></v-select>
              <v-switch
                class="mr-2"
                v-model="onlyscanned"
                label="Show Scanned Only"
                dense
              ></v-switch>
              <v-switch
                v-model="hideMultiple"
                label="Hide Multiple Scans"
                dense
              ></v-switch>
            </v-col>
            <v-col>
              <v-btn @click="getData()" block tile color="primary"
                >Generate/Refresh</v-btn
              >
              <v-switch
                v-model="onlyErrors"
                label="Show Only Errors"
                dense
              ></v-switch>

              <v-btn
                :disabled="!selected.length"
                @click="createVerification()"
                block
                tile
                color="primary"
                class="mt-3"
                >Create veryfication</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="headers"
                v-model="selected"
                show-select
                :items="
                  items.filter(
                    (l) =>
                      (onlyErrors ? l.lastQty != l.lastQuantityonhand : true) &&
                      (!hideMultiple ||
                        (hideMultiple && Object.keys(l.checksSum).length < 2))
                  )
                "
                :loading="loading"
                loading-text="Loading... Please wait"
                item-key="_id"
                :search="search"
                :footer-props="{ itemsPerPageOptions: rows }"
                :custom-filter="filter"
              >
                <template v-slot:item.item="{ item }">
                  <router-link
                    v-if="item.item"
                    :to="{
                      path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
                    }"
                    class="link"
                    >{{ item.item.displayname || item.item.name }}</router-link
                  >
                </template>
                <template v-slot:item.laststockcheck="{ item }">
                  {{
                    new Date(item.laststockcheck).toISOString().substr(0, 10)
                  }}
                  ({{ item.stockchecktype }})
                </template>
                <template v-slot:item.locations="{ item }">
                  <v-chip label v-if="item.locations.length"
                    >{{ item.locations[0].bin }}
                    {{
                      bincontrol
                        ? "(" + item.locations[0].quantityonhand + ")"
                        : ""
                    }}</v-chip
                  >
                  <v-chip label v-if="item.locations.length > 1"
                    >+ {{ item.locations.length - 1 }} more</v-chip
                  >
                </template>
                <template v-slot:item.checksSum="{ item }">
                  <v-chip
                    label
                    class="mr-1"
                    v-for="(check, index) of item.checksSum"
                    :key="index"
                    :color="
                      check.quantity == check.quantityonhand
                        ? !check.quantity
                          ? 'warning'
                          : 'success'
                        : 'error'
                    "
                    >{{ index }} ({{ check.quantity }}/{{
                      check.quantityonhand
                    }})</v-chip
                  >
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-row>
          <v-col>
            <v-data-table
              :headers="headers2"
              :items="items2"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :footer-props="{ itemsPerPageOptions: rows }"
              :custom-filter="filter"
            >
              <template v-slot:item.item="{ item }">
                <v-combobox
                  class="my-2"
                  v-model="item.item"
                  item-text="name"
                  item-value="_id"
                  :items="itemsList"
                  return-object
                  label="Item"
                  outlined
                  dense
                  hide-details
                  v-on:keyup.enter="getItems(item.item)"
                  @change="changeItem($event, item._id, 'item')"
                  hint="type item name and put enter to find"
                  :disabled="loading"
                >
                </v-combobox>
              </template>
              <template v-slot:item.quantityonhand="{ item }">
                <v-text-field
                  class="my-2"
                  hide-details
                  type="number"
                  label="Qty. Expected"
                  min="0"
                  outlined
                  dense
                  v-model="item.quantityonhand"
                  @change="changeItem($event, item._id, 'quantityonhand')"
                ></v-text-field>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
  <script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer"],
  data() {
    return {
      tab: "overview",
      hideMultiple: false,
      menu: false,
      loading: false,
      itemsList: [],
      onlyErrors: false,
      onlyscanned: true,
      bincontrol: false,
      headers: [
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Item", value: "item" },
        { text: "Name", value: "name" },
        { text: "Last Stock Check", value: "laststockcheck" },
        { text: "Current on Hand", value: "quantityonhand" },
        { text: "Locations", value: "locations", sortable: false },
        {
          text: "Checks (Scanned/Expected)",
          value: "checksSum",
          sortable: false,
        },
        {
          text: "Scanned",
          value: "lastQty",
        },
        {
          text: "Expected",
          value: "lastQuantityonhand",
        },
        {
          text: "Difference (Scanned/Expected)",
          value: "difference",
        },
        {
          text: "Avg. Value",
          value: "price",
        },
      ],
      headersExport: [
        { text: "Manufacturer", value: "manufacturergroup" },
        { text: "Item", value: "item" },
        { text: "Name", value: "name" },
        { text: "Last Stock Check", value: "laststockcheck" },
        { text: "Current on Hand", value: "quantityonhand" },
        { text: "Locations", value: "locations", sortable: false },
        {
          text: "Scanned",
          value: "lastQty",
        },
        {
          text: "Expected",
          value: "lastQuantityonhand",
        },
        {
          text: "Difference (Scanned/Expected)",
          value: "difference",
        },
        {
          text: "Avg. Value",
          value: "price",
        },
      ],
      headers2: [
        { text: "Item", value: "item" },
        { text: "Name", value: "name" },
        { text: "Qty.", value: "quantity" },
        { text: "Bin", value: "bin" },
        { text: "Qty. Expected", value: "quantityonhand" },
        { text: "Employee", value: "employee" },
      ],
      selected: [],
      items: [],
      items2: [],
      rows: [100],
      search: "",
      show: false,
      date: new Date().toISOString().substr(0, 10),
      location: "5e0dbaba9e33df43f0b3a495",
      locations: [
        { name: "PL", _id: "5e0dbaba9e33df43f0b3a495" },
        { name: "UK", _id: "61e14c6ae4a35a668517a8fe" },
      ],
    };
  },
  async created() {
    this.loading = true;
    //await this.getData();
    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getStockCheck",
          location: this.location,
          date: this.date,
          onlyscanned: this.onlyscanned,
        },
        false,
        false
      );
      this.items = result.items;
      this.items2 = result.unrecognised;
      this.bincontrol = result.bincontrol;
      this.loading = false;
    },
    async createVerification() {
      let number = prompt("Please enter number of veryfication:", 1);
      if (number) {
        let lines = [];
        this.selected.forEach((s) => {
          let bins = [];
          s.locations.forEach((l) => {
            bins.push(l.bin);
          });
          Object.values(s.checks || {}).forEach((c) => {
            bins.push(...Object.keys(c));
          });
          bins = [...new Set(bins)];
          bins.forEach((b) => {
            lines.push({ item: s.item, bin: b });
          });
        });

        lines.sort((a, b) => {
          const nameA = a.bin.toUpperCase(); // ignore upper and lowercase
          const nameB = b.bin.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });

        this.$router.push({
          name: "Transactions",
          params: {
            recordtype: "stockcheck",
            location: this.location,
            mode: "create",
            stockchecks: lines,
            description: `Verifcation ${number}`,
          },
        });
      }
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    async changeItem(e, id, field) {
      let update = {};
      console.log(e);
      if (e) {
        update[field] = e._id || e;
        console.log(update);
        await service("items_stockcheck", {
          action: "updateOne",
          data: [{ _id: id }, { $set: update }],
        });
      }
    },
    async getItems(item) {
      console.log(item);
      if (item._id) item = item.name;
      this.itemsList = await service("items", {
        action: "find",
        data: [
          {
            recordtype: { $in: ["inventoryitem"] },
            $or: [
              { name: { $regex: `.*${item}.*`, $options: "i" } },
              { displayname: { $regex: `.*${item}.*`, $options: "i" } },
            ],
          },
        ],
        limit: 100,
      }).then((response) => {
        response.forEach((v) => {
          v.name = v.displayname || v.name;
        });
        return response;
      });
    },
  },
  components: {
    exportData,
  },
};
</script>
<template>
  <div>
    <v-row>
      <v-col>
        <v-tabs dark v-model="active">
          <v-tab :key="1">Sent Emails</v-tab>
          <v-tab :key="2" v-if="record.recordtype == 'customer'">Notes</v-tab>
          <v-tab :key="3">Inbox</v-tab>

          <v-tab-item :key="1">
            <v-data-table
              :headers="headers"
              :items="sentemails"
              :loading="loading"
              loading-text="Loading... Please wait"
              item-key="_id"
              :search="search"
              :footer-props="{ itemsPerPageOptions: rows }"
            >
              <template v-slot:item.date="{ item }">
                <span v-if="item.created">{{
                  item.date
                    ? new Date(
                        new Date(item.date).getTime() -
                          new Date(item.date).getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(0, 10) +
                      " " +
                      new Date(
                        new Date(item.date).getTime() -
                          new Date(item.date).getTimezoneOffset() * 60000
                      )
                        .toISOString()
                        .substr(11, 5)
                    : ""
                }}</span>
                <span v-else>{{ item.date }}</span>
              </template>
              <template v-slot:header.text="{ header }"></template>
              <template v-slot:item.text="{ item }"></template>
              <template v-slot:item.action="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">mail</v-icon>
              </template>
              <template v-slot:item.to="{ item }">{{
                item.to.toString()
              }}</template>
              <template v-slot:top>
                <v-container fluid>
                  <v-row>
                    <v-col cols="2">
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <reademail
                        :uid="uid"
                        :record="record"
                        :emailaddress="email"
                        :box="box"
                        @emit="catchEmit"
                      ></reademail>
                    </v-col>
                  </v-row>
                </v-container>
              </template>
            </v-data-table>
            <writeemail
              :record="record"
              label="Compose Email"
              v-if="record"
            ></writeemail>
          </v-tab-item>
          <v-tab-item :key="2" v-if="record.recordtype == 'customer'">
            <tool-activities :record="record" />
          </v-tab-item>
          <v-tab-item :key="3">
            <v-data-table
              :headers="headers"
              :items="items"
              :search="search"
              :loading="loading"
              loading-text="Loading... Please wait"
            >
              <template v-slot:header.text="{ header }"></template>
              <template v-slot:item.text="{ item }"></template>
              <template v-slot:item.action="{ item }">
                <v-icon class="mr-2" @click="editItem(item)">mail</v-icon>
              </template>

              <template v-slot:top>
                <reademail
                  :uid="uid"
                  :emailaddress="email"
                  :box="box"
                  @emit="catchEmit"
                ></reademail>
              </template>
              <template v-slot:footer>
                <v-divider></v-divider>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model="search"
                        append-icon="search"
                        label="Search"
                        single-line
                        hide-details
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <exportData :headers="headers" :items="items" />
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="text-right">
                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-expand-transition>
                    <div v-show="show">
                      <v-divider></v-divider>
                      <v-row>
                        <v-col>
                          <v-select
                            :items="emails"
                            v-model="email"
                            label="Email"
                            item-text="name"
                            item-value="_id"
                            outlined
                            required
                            dense
                          ></v-select>
                        </v-col>
                        <v-col>
                          <writeemail
                            :record="record"
                            label="Compose"
                            v-if="record"
                          ></writeemail>
                        </v-col>
                      </v-row>
                    </div>
                  </v-expand-transition>
                </v-container>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import { getInbox } from "../../common/methods/email";
import service from "../../api/index";
import reademail from "./read";
import writeemail from "./write";
import exportData from "../dataTable/exportData";
export default {
  props: ["customer", "record", "filter"],
  data() {
    return {
      active: 0,
      loading: true,
      search: "",
      emails: [],
      sentemails: [],
      email: null,
      box: "INBOX",
      show: true,
      items: [],
      uid: null,
      rows: [25, 100],
      headers: [
        { text: "Subject", value: "subject" },
        { text: "From", value: "from" },
        { text: "To", value: "to" },
        { text: "Date", value: "date" },

        { text: "Read", value: "action", sortable: false },
        { text: "Text", value: "text" },
      ],
    };
  },
  async created() {
    this.loading = true;
    let data = {
      action: "find",
      data: [
        {
          recordtype: "sentemail",
          // $or: [
          //   { user: this.record._id },
          //   { record: this.record._id },
          //   { campaign: this.record._id },
          //   { parent: this.record._id },
          // ],
        },
        {
          projection: {
            subject: true,
            from: true,
            to: true,
            date: true,
            created: true,
            box: true,
            uid: true,
            _id: true,
          },
        },
      ],
      limit: 250,
      sort: { _id: -1 },
    };
    data.data[0][this.filter || "record"] = this.record._id;
    this.sentemails = await service("sentemails", data)
      .catch((error) => {
        throw error;
      })
      .then((response) => {
        return response;
      });

    if (this.email && this.box)
      this.items = await getInbox(this, this.email, this.customer);
    data = {
      action: "find",
      data: [
        { recordtype: "emailbox", incomingmailhost: "imap.gmail.com" },
        {},
      ],
    };
    this.emails = await service("emails", data)
      .catch((error) => {
        throw error;
      })
      .then((response) => {
        return response;
      });

    this.loading = false;
  },
  watch: {
    async email(value) {
      this.loading = true;
      if (this.email && this.box)
        this.items = await getInbox(this, this.email, this.customer);
      this.loading = false;
    },
  },
  methods: {
    editItem(item) {
      this.uid = item.uid || item._id;
      this.box = item.box;
    },
    catchEmit(e) {
      if (!e.reademail) this.uid = null;
    },
  },
  components: {
    reademail: reademail,
    writeemail: writeemail,
    exportData,
  },
};
</script>
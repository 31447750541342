<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.lastpercent="{ item }">
            <v-chip
              label
              v-if="item.lastpercent"
              :color="parseFloat(item.lastpercent) > 0 ? 'green' : 'red'"
              dark
              >{{ item.lastpercent }}</v-chip
            >
          </template>
          <template v-slot:item.percent="{ item }">
            <v-chip
              label
              :color="parseFloat(item.percent) > 0 ? 'green' : 'red'"
              dark
              >{{ item.percent }}</v-chip
            >
          </template>
          <template v-slot:item.percent2="{ item }">
            <v-chip
              label
              :color="parseFloat(item.percent2) > 0 ? 'green' : 'red'"
              dark
              >{{ item.percent2 }}</v-chip
            >
          </template>
          <template slot="body.append">
            <tr v-if="items.length > 1">
              <th v-for="(col, index) in headers" :key="index">
                {{ calcTotal(col.value) }}
              </th>
            </tr>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import service from "../../../api/index";
import exportData from "../../dataTable/exportData";
export default {
  props: ["customer", "record"],
  data() {
    return {
      active: 0,
      loading: true,
      search: "",
      show: true,
      items: [],
      rid: null,
      headers: [
        { text: "Manufacturer", value: "manufacturer" },
        { text: "Sales (Last Year)", value: "lastsales" },
        { text: "Sales (This Year)", value: "sales" },
        { text: "% (Sales(TY)/Sales(LY))", value: "lastpercent" },
        { text: "Target", value: "salestarget" },
        { text: "% (Sales/Target)", value: "percent" },
        { text: "Forecast", value: "forecast" },
        { text: "% (Forecast/Target)", value: "percent2" },
      ],
    };
  },
  async created() {
    this.loading = true;
    let items = await service("sales", {
      action: "getSalesTargets",
      user: this.record,
    }, false, true);
    this.items = items.items;
    this.loading = false;
  },
  methods: {
    calcTotal(key) {
      if (
        this.items.length > 0 &&
        !["lastpercent", "percent", "percent2"].includes(key)
      ) {
        let sum = this.items.reduce((a, b) => {
          if (
            !isNaN(parseFloat(a[key] || a)) &&
            !isNaN(parseFloat(b[key] || b))
          ) {
            return parseFloat(a[key] || a || 0) + parseFloat(b[key] || b || 0);
          } else return parseFloat(a || 0);
        });

        return sum && !isNaN(parseInt(sum)) ? sum.toFixed(2) : "";
      }
    },
  },
  components: {
    exportData: exportData,
  },
};
</script>
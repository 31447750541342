<template>
  <v-tabs vertical dark>
    <v-tooltip bottom>
      <span>Overview</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-information-outline</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Related Transactions</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-format-list-bulleted</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Statistics</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-chart-line</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tooltip bottom>
      <span>Logs</span>
      <template v-slot:activator="{ on }">
        <v-tab ripple v-on="on">
          <v-icon left>mdi-math-log</v-icon>
        </v-tab>
      </template>
    </v-tooltip>
    <v-tab-item>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-card flat>
              <v-list>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">phone</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>Contact Phone:</v-list-item-subtitle>
                    <v-list-item-title>{{ record.phone }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>

                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">mail</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>Primary Email:</v-list-item-subtitle>
                    <v-list-item-title>{{ record.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider inset></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">business</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      billingaddress.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      billingaddress.address
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">local_shipping</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-title>{{
                      shippingaddress.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle>{{
                      shippingaddress.address
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-action>
                    <v-icon color="primary">comment</v-icon>
                  </v-list-item-action>

                  <v-list-item-content>
                    <div class="notes grey--text">{{ record.notes }}</div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
          <v-col>
            <field-map :addresses="addresses" />
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <tool-relatedtransactions :record="record" />
    </v-tab-item>
    <v-tab-item>
      <statistics :manufacturergroup="record._id" mode="sales" />
    </v-tab-item>
    <v-tab-item>
      <tool-logs :record="record._id" />
    </v-tab-item>
  </v-tabs>
</template>
<script>
/* eslint-disable */
import widget from "../../../widgets/test1";
import statistics from "../../../components/tools/statistics/View";
export default {
  props: ["record"],
  data() {
    return {
      loading: false,
      photosrc: "",
    };
  },
  watch: {
    record: {
      handler: async function (after, before) {},
      deep: true,
    },
  },
  async created() {},
  computed: {
    addresses() {
      let addresses = [];
      if (this.record.billingaddress)
        addresses.push(this.record.billingaddress);
      if (this.record.shippingaddress)
        addresses.push(this.record.shippingaddress);
      return addresses;
    },
    billingaddress() {
      let address = { name: "", address: "" };
      if (this.record.billingaddress) {
        address.name =
          this.record.billingaddress.addressee ||
          this.record.billingaddress.name;
        if (this.record.billingaddress.address)
          address.address += this.record.billingaddress.address;
        if (this.record.billingaddress.zip)
          address.address += `, ${this.record.billingaddress.zip}`;
        if (this.record.billingaddress.city)
          address.address += `, ${this.record.billingaddress.city}`;
        if (this.record.billingaddress.country)
          address.address += `, ${
            this.record.billingaddress.country.name ||
            this.record.billingaddress.country
          }`;
      }
      return address;
    },
    shippingaddress() {
      let address = { name: "", address: "" };
      if (this.record.shippingaddress) {
        address.name =
          this.record.shippingaddress.addressee ||
          this.record.shippingaddress.name;
        if (this.record.shippingaddress.address)
          address.address += this.record.shippingaddress.address;
        if (this.record.shippingaddress.zip)
          address.address += `, ${this.record.shippingaddress.zip}`;
        if (this.record.shippingaddress.city)
          address.address += `, ${this.record.shippingaddress.city}`;
        if (this.record.shippingaddress.country)
          address.address += `, ${
            this.record.shippingaddress.country.name ||
            this.record.shippingaddress.country
          }`;
      }
      return address;
    },
  },
  components: {
    widget,
    statistics
  },
};
</script>
<style scoped>
.notes {
  font-size: 14px;
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"background-color":"primary","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Stock Check Summary ")]),_c('v-tab',[_vm._v(" Unrecognised Items ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"dense":"","append-icon":"search","label":"Search","single-line":"","hide-details":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('exportData',{attrs:{"headers":_vm.headersExport,"items":_vm.items}})],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"From:","placeholder":"","append-icon":"event","readonly":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-select',{staticClass:"mt-2 mb-2",attrs:{"items":_vm.locations,"label":"Warehouse","item-text":"name","item-value":"_id","outlined":"","clearable":"","hide-details":"","dense":""},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('v-switch',{staticClass:"mr-2",attrs:{"label":"Show Scanned Only","dense":""},model:{value:(_vm.onlyscanned),callback:function ($$v) {_vm.onlyscanned=$$v},expression:"onlyscanned"}}),_c('v-switch',{attrs:{"label":"Hide Multiple Scans","dense":""},model:{value:(_vm.hideMultiple),callback:function ($$v) {_vm.hideMultiple=$$v},expression:"hideMultiple"}})],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.getData()}}},[_vm._v("Generate/Refresh")]),_c('v-switch',{attrs:{"label":"Show Only Errors","dense":""},model:{value:(_vm.onlyErrors),callback:function ($$v) {_vm.onlyErrors=$$v},expression:"onlyErrors"}}),_c('v-btn',{staticClass:"mt-3",attrs:{"disabled":!_vm.selected.length,"block":"","tile":"","color":"primary"},on:{"click":function($event){return _vm.createVerification()}}},[_vm._v("Create veryfication")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"show-select":"","items":_vm.items.filter(
                  function (l) { return (_vm.onlyErrors ? l.lastQty != l.lastQuantityonhand : true) &&
                    (!_vm.hideMultiple ||
                      (_vm.hideMultiple && Object.keys(l.checksSum).length < 2)); }
                ),"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","search":_vm.search,"footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
                var item = ref.item;
return [(item.item)?_c('router-link',{staticClass:"link",attrs:{"to":{
                    path: ("/" + (item.item.collection) + "/" + (item.item.recordtype) + "/view/" + (item.item._id)),
                  }}},[_vm._v(_vm._s(item.item.displayname || item.item.name))]):_vm._e()]}},{key:"item.laststockcheck",fn:function(ref){
                  var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.laststockcheck).toISOString().substr(0, 10))+" ("+_vm._s(item.stockchecktype)+") ")]}},{key:"item.locations",fn:function(ref){
                  var item = ref.item;
return [(item.locations.length)?_c('v-chip',{attrs:{"label":""}},[_vm._v(_vm._s(item.locations[0].bin)+" "+_vm._s(_vm.bincontrol ? "(" + item.locations[0].quantityonhand + ")" : ""))]):_vm._e(),(item.locations.length > 1)?_c('v-chip',{attrs:{"label":""}},[_vm._v("+ "+_vm._s(item.locations.length - 1)+" more")]):_vm._e()]}},{key:"item.checksSum",fn:function(ref){
                  var item = ref.item;
return _vm._l((item.checksSum),function(check,index){return _c('v-chip',{key:index,staticClass:"mr-1",attrs:{"label":"","color":check.quantity == check.quantityonhand
                      ? !check.quantity
                        ? 'warning'
                        : 'success'
                      : 'error'}},[_vm._v(_vm._s(index)+" ("+_vm._s(check.quantity)+"/"+_vm._s(check.quantityonhand)+")")])})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers2,"items":_vm.items2,"loading":_vm.loading,"loading-text":"Loading... Please wait","item-key":"_id","footer-props":{ itemsPerPageOptions: _vm.rows },"custom-filter":_vm.filter},scopedSlots:_vm._u([{key:"item.item",fn:function(ref){
                      var item = ref.item;
return [_c('v-combobox',{staticClass:"my-2",attrs:{"item-text":"name","item-value":"_id","items":_vm.itemsList,"return-object":"","label":"Item","outlined":"","dense":"","hide-details":"","hint":"type item name and put enter to find","disabled":_vm.loading},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getItems(item.item)},"change":function($event){return _vm.changeItem($event, item._id, 'item')}},model:{value:(item.item),callback:function ($$v) {_vm.$set(item, "item", $$v)},expression:"item.item"}})]}},{key:"item.quantityonhand",fn:function(ref){
                      var item = ref.item;
return [_c('v-text-field',{staticClass:"my-2",attrs:{"hide-details":"","type":"number","label":"Qty. Expected","min":"0","outlined":"","dense":""},on:{"change":function($event){return _vm.changeItem($event, item._id, 'quantityonhand')}},model:{value:(item.quantityonhand),callback:function ($$v) {_vm.$set(item, "quantityonhand", $$v)},expression:"item.quantityonhand"}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<script>
import { Bar, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Bar,
  props: ["data", "option", "chartData"],
  mixins: [reactiveProp],
  data: () => ({
    options: {
      legend: {
        display: true,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        labels: {
          render: () => "",
        },
      },
    },
  }),

  mounted() {
    this.options = Object.assign(this.options, this.option);
    this.options.legend.display =
      this.chartData.datasets.length > 10 ? false : true;
    this.renderChart(this.chartData, this.options);
  },
};
</script>
<template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :loading="loading"
          loading-text="Loading... Please wait"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.note="{ item }">
            <pre>{{ item.note }}</pre>
          </template>
          <template v-slot:item.type="{ item }">
            <span>{{ item.eventtypetext || item.typename }}</span>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip v-if="item.taskstatustext" label :color="item.color">{{
              item.taskstatustext || ""
            }}</v-chip>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
              class="mr-2"
              @click="create(item.recordtype, 'view', item._id)"
              >launch</v-icon
            >
            <v-icon
              class="mr-2"
              @click="create(item.recordtype, 'edit', item._id)"
              >edit</v-icon
            >
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-spacer></v-spacer>
                <v-col>
                  <v-btn block tile color="primary" @click="create('note')">
                    New Note
                    <v-icon right dark>mdi-notebook</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block tile color="primary" @click="create('task')">
                    New Task
                    <v-icon right dark>event_note</v-icon>
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block tile color="primary" @click="create('event')">
                    New Event
                    <v-icon right dark>event</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import service from "../../../api/index";

export default {
  props: ["customer", "record"],
  data() {
    return {
      active: 0,
      loading: true,
      search: "",
      show: true,
      items: [],
      rid: null,
      headers: [
        { text: "Action", value: "action" },
        { text: "Date", value: "date" },
        { text: "Record Type", value: "typename" },
        { text: "Employee", value: "employee" },
        //{ text: "Customer", value: "customer" },
        { text: "Type", value: "type" },
        { text: "Status", value: "status" },
        { text: "Note", value: "note", width: "400px" },
      ],
    };
  },
  async created() {
    this.loading = true;

    let items = await service(
      "activities",
      {
        action: "getActivities",
        record: this.record._id,
      },
      false,
      true
    );
    this.items = items.items;
    this.loading = false;
  },
  watch: {},
  methods: {
    async create(recordtype, mode = "create", rid, customer = this.record._id) {
      this.$router.push({
        name: "Activities",
        params: {
          recordtype: recordtype,
          mode: mode,
          id: rid,
          employee: this.$store.state.user._id,
          customer: customer,
        },
      });
    },
  },
  //   components: {
  //     reademail: reademail,
  //     writeemail: writeemail
  // }
};
</script>
 <template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      persistent
      max-width="1000px"
    >
      <template v-slot:activator="{ on }">
        <v-btn block tile color="primary" v-on="on">{{ label }}</v-btn>
      </template>
      <v-card tile flat v-if="!overlay">
        <v-toolbar flat dark>
          <v-btn text tile> <v-icon @click="close">mdi-close</v-icon></v-btn>
          New Email
          <div class="flex-grow-1"></div>
        </v-toolbar>

        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="newemail.from"
                    :items="from"
                    outlined
                    label="From"
                    item-text="name"
                    item-value="_id"
                    dense
                  ></v-autocomplete>
                  <v-combobox
                    v-model="newemail.to"
                    :items="newemail.to"
                    outlined
                    label="To"
                    multiple
                    dense
                    clearable
                  ></v-combobox>
                  <v-combobox
                    v-model="newemail.cc"
                    :items="newemail.cc"
                    outlined
                    label="CC"
                    multiple
                    dense
                    clearable
                  ></v-combobox>
                  <v-text-field
                    v-model="newemail.subject"
                    outlined
                    label="Subject"
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row v-if="type">
                <v-col>
                  <v-select
                    :items="recordTypes"
                    v-model="type"
                    label="Record Type"
                    item-text="name"
                    item-value="recordtype"
                    return-object
                    outlined
                    dense
                    disabled
                  ></v-select>
                </v-col>
                <v-col>
                  <v-select
                    :items="templates"
                    v-model="emailtemplate"
                    label="Template"
                    item-text="name"
                    item-value="_id"
                    return-object
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6" v-if="false">
                  <v-autocomplete
                    v-model="newemail.attachedFiles"
                    :items="files"
                    label="Attachments"
                    multiple
                    outlined
                    append-outer-icon="add_box"
                    item-text="name"
                    item-value="_id"
                    dense
                    disabled
                  ></v-autocomplete>
                </v-col>
                <v-col sm="6" v-if="false">
                  <v-autocomplete
                    v-model="newemail.attachedRecords"
                    :items="records"
                    label="Transactions"
                    multiple
                    outlined
                    item-text="name"
                    item-value="_id"
                    dense
                    disabled
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <yimo-vue-editor
                v-model="newemail.text"
                v-if="template ? false : true"
              ></yimo-vue-editor>
            </v-container>
          </v-form>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-btn
            tile
            color="primary"
            class="pl-4"
            @click="send"
            :disabled="!newemail.to || (newemail.to && !newemail.to.length)"
            >Send Email <v-icon right>mdi-send</v-icon></v-btn
          >
          <v-btn tile @click="close">Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="msg.show"
      :color="msg.type"
      :timeout="6000"
      top
      absolute
      right
      >{{ msg.message }}</v-snackbar
    >
  </div>
</template>
<script>
/* eslint-disable */
import { getEmail, sendEmail } from "../../common/methods/email";
import YimoVueEditor from "yimo-vue-editor";
import service from "../../api/index";
export default {
  props: ["record", "uid", "label", "email", "emailtemplate"],
  data() {
    return {
      newemail: {
        subject: "",
        to: [],
        cc: [],
        from: null,
        text: "",
        date: "",
        attachedRecords: [],
        attachedFiles: [],
        record: null,
        collection: null,
        recordtype: null,
      },
      msg: false,
      overlay: false,
      dialog: false,
      lastuid: null,
      type: null,
      templates: [],
      template: null,
      //emailtemplate: null,
      records: [],
      files: [],
      from: [],
      signature: "",
      config: {
        theme: "modern",
        fontsize_formats:
          "8px 10px 12px 14px 16px 18px 20px 22px 24px 26px 39px 34px 38px 42px 48px",
        plugins: "print preview fullpage powerpaste searchreplace autolink",
        toolbar1:
          "formatselect fontsizeselect | bold italic strikethrough forecolor backcolor link",
      },
      recordTypes: [
        {
          recordtype: "salesorder",
          name: "Sales Order",
          collection: "transactions",
        },
        {
          recordtype: "invoice",
          name: "Invoice",
          collection: "transactions",
        },
        {
          recordtype: "purchaseorder",
          name: "Purchase Order",
          collection: "transactions",
        },
        {
          recordtype: "creditmemo",
          name: "Credit Memo",
          collection: "transactions",
        },
        { recordtype: "customer", name: "Customer", collection: "users" },
        { recordtype: "vendor", name: "Vendor", collection: "users" },
      ],
    };
  },
  watch: {
    async type(value) {
      // console.log(value)
      // this.newemail.collection = value.collection;
      // this.newemail.recordtype = value.recordtype;
      this.templates = null;
      this.templates = await this.getData("emails", [
        {
          recordtype: "emailtemplate",
          hidden: { $ne: true },
          type: { $in: [this.record.recordtype, null] },
          company: {
            $in: [this.record.company ? this.record.company._id : null, null],
          },
          role: {
            $in: [
              this.$store.state.user && this.$store.state.user.role._id
                ? this.$store.state.user.role._id
                : null,
              null,
              undefined,
            ],
          },
          $or: [
            {
              forsources: this.record.source
                ? this.record.source._id
                : { $ne: [] },
            },
            { forsources: [] },
            { forsources: { $exists: false } },
          ],
        },
      ]);
      this.records = [];
      // this.records = await this.getData("transactions", [
      //   { recordtype: value.recordtype },
      //   { name: true, recordtype: true, collection: true }
      // ]);
    },
    async emailtemplate(value) {
      if (value) {
        let params = { eta: new Date(), vendor: "" };
        value.text = value.text.replaceAll("record.", "this.record.");
        value.subject = value.subject.replaceAll("record.", "this.record.");

        this.newemail.text = eval("`" + value.text + "`");
        this.newemail.subject = eval("`" + value.subject + "`");
        if (value.receiverto) this.newemail.to = value.receiverto.split(",");
        // if (
        //   !this.email &&
        //   (this.record.recordtype == "customer" ||
        //     this.record.recordtype == "vendor" ||
        //     this.record.collection == "transactions")
        // ) {
        //   this.newemail.to = [];
        //   await service("users_contacts", {
        //     action: "find",
        //     data: [
        //       {
        //         customer:
        //           this.record.collection == "transactions"
        //             ? this.record.customer._id
        //             : this.record._id,
        //       },
        //     ],
        //   }).then((response) => {
        //     (response || []).forEach((contact) => {
        //       this.newemail.to.push(contact.email);
        //     });
        //   });
        // }
      }
      // this.newemail.text = `${this.template.template} ${this.template.signature}`;
    },
  },
  async created() {
    if (this.record) {
      this.newemail.collection = this.record.collection;
      this.newemail.recordtype = this.record.recordtype;
      this.type = this.record.recordtype;
    }
    // this.records = await this.getData("transactions", [
    //   { recordtype: this.type },
    //   { name: true, recordtype: true, collection: true }
    // ]);
    this.templates = await this.getData("emails", [
      {
        recordtype: "emailtemplate",
        hidden: { $ne: true },
        type: { $in: [this.record.recordtype, null] },
        company: {
          $in: [this.record.company ? this.record.company._id : null, null],
        },
        role: {
          $in: [
            this.$store.state.user && this.$store.state.user.role._id
              ? this.$store.state.user.role._id
              : null,
            null,
            undefined,
          ],
        },
        $or: [
          {
            forsources: this.record.source
              ? this.record.source._id
              : { $ne: [] },
          },
          { forsources: [] },
          { forsources: { $exists: false } },
        ],
      },
    ]);
    // this.files = await this.getData("files", [
    //   {},
    //   { name: true, recordtype: true, collection: true },
    // ]);
    this.from = await this.getData("emails", [
      { recordtype: "emailbox" },
      {
        name: true,
        recordtype: true,
        collection: true,
        user: true,
      },
    ]);
    if (this.$store.state.user._id)
      this.newemail.from = this.from.find(
        (e) => e.user == this.$store.state.user._id
      );
    if (this.record.collection == "users") {
      this.newemail.subject = "";
      this.newemail.record = this.record._id;
      this.newemail.to = [this.record.email];
    }

    if (this.record.collection == "transactions") {
      this.newemail.subject = `${this.record.name}`;
      this.newemail.to = this.record.email
        ? Array.isArray(this.record.email)
          ? this.record.email
          : [this.record.email]
        : Array.isArray(this.record.customer.email)
        ? this.record.customer.email
        : [this.record.customer.email];
    }
    if (this.record._id) {
      this.newemail.record = this.record._id;
      if (this.attachrecord)
        this.newemail.attachedRecords.push(this.record._id);
    }

    if (this.email) {
      this.newemail.subject = this.email.subject
        ? "RE: " + this.email.subject
        : "";
      this.newemail.to = [this.email.from];
      //this.newemail.text = `${this.template.template} ${this.signature}`;
    }

    if (this.emailtemplate) {
      this.emailtemplate.text = this.emailtemplate.text.replaceAll(
        "record.",
        "this.record."
      );
      this.emailtemplate.subject = this.emailtemplate.subject.replaceAll(
        "record.",
        "this.record."
      );

      this.newemail.text = eval("`" + this.emailtemplate.text + "`");
      this.newemail.subject = eval("`" + this.emailtemplate.subject + "`");
      if (this.emailtemplate.receiverto)
        this.newemail.to = this.emailtemplate.receiverto.split(",");
      //console.log(this.emailtemplate);
      if (this.emailtemplate.receivercc)
        this.newemail.cc = this.emailtemplate.receiverc.split(",");
      if (this.emailtemplate.cc) this.newemail.cc = this.emailtemplate.cc;
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    async send() {
      try {
        console.log(this.newemail);
        this.overlay = true;
        let status = await sendEmail(this, this.newemail);
        this.overlay = false;
        this.$emit("emit", {
          action: "sentemail",
          number: this.emailtemplate.number,
        });

        if (status) {
          this.msg = {
            message: `${status.message}`,
            show: true,
            type: "success",
          };
        }
        this.dialog = false;
      } catch (error) {
        this.msg = { message: `${error.message}`, show: true, type: "error" };
      }
    },
    getData(collection, parameters = []) {
      let data = {
        action: "find",
        data: parameters,
      };
      //console.log("Select", collection,data);
      return service(collection, data)
        .catch((error) => {
          throw error;
        })
        .then((response) => {
          //console.log("select", response);
          let options = response.map((obj) => {
            return obj;
          });
          return options;
        })
        .catch((error) => {});
    },
  },
  components: {
    YimoVueEditor,
  },
};
</script>
<style  scoped>
.v-dialog__container {
  display: unset;
}
</style>
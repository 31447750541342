<template>
  <v-autocomplete
    @focus="active = true"
    @blur="active = false"
    @change="active = false"
    :error="
      required && (!recordSelected || (multiple && !recordSelected.length))
    "
    v-model="recordSelected"
    :items="records"
    :item-text="displayfield"
    item-value="_id"
    :label="label"
    placeholder
    :multiple="multiple"
    :rules="[rules.required]"
    :return-object="recordObj"
    :readonly="
      ($route.params.mode == 'view' ||
        this.formSettings.disabled ||
        this.fieldSettings.disabled == true ||
        record.archived ||
        loading) &&
      !this.formSettings.show
    "
    :disabled="
      (this.formSettings.disabled || this.fieldSettings.disabled == true) &&
      !this.formSettings.show
    "
    persistent-hint
    :hint="hint"
    outlined
    :clearable="$route.params.mode == 'view' || record.archived ? false : true"
    :loading="loading"
    dense
    :prepend-icon="
      $route.params.mode == 'view' || !this.fieldSettings.setup.add
        ? ''
        : 'add_box'
    "
    @click:prepend="add"
    autocomplete="new-password"
  ></v-autocomplete>
</template>

<script>
import service from "../../api/index";
import fieldCalculated from "../../common/methods/fieldCalculated";
import getFields from "../../common/methods/getFields";
/* eslint-disable */
export default {
  props: ["value", "record", "fieldSettings", "formSettings", "parent"],
  data() {
    return {
      records: [],
      recordSelected: this.value || null,
      recordObj: true,
      required:
        this.formSettings.required ||
        this.fieldSettings.setup.required ||
        false,
      multiple:
        this.formSettings.multiple ||
        this.fieldSettings.setup.multiple ||
        ["$in", "$nin"].includes(this.formSettings.operator),
      field: this.formSettings.field || this.formSettings.setup.field,
      label: this.formSettings.name || this.fieldSettings.name,
      rules: {
        required: (value) => (this.required ? !!value || "Required." : true),
      },
      mode: this.$route.params.mode,
      loading: false,
      displayfield: this.fieldSettings.setup.displayfield || "displayname",
      active: false,
    };
  },
  watch: {
    async active(v) {
      if (v)
        this.records = await this.getData(
          this.fieldSettings,
          this.formSettings
        );
    },
    async recordSelected(value) {
      if (this.formSettings.show) this.field = "value";
      if (this.record[this.field] != value)
        this.$emit("emit", { field: this.field, value: value || null });
    },
    record: {
      handler: async function (after, before) {
        if (this.formSettings.show) {
          this.field = "value";
        }

        if (this.record[this.field]) {
          let value = this.record[this.field];
          if (Array.isArray(value))
            value.forEach((val) => {
              if (val._id) val.displayname = val.displayname || val.name;
            });
          else {
            value = this.record[this.field];
            if (value.displayname || value.name)
              value.displayname = value.displayname || value.name;
          }
          this.recordSelected = this.record[this.field];
          if (this.multiple) this.records.push(...this.record[this.field]);
          else {
            if (this.record[this.field]._id)
              this.records.push(this.record[this.field]);
            // else
            //   this.records = await this.getData(
            //     this.fieldSettings,
            //     this.formSettings
            //   );
          }
        } else this.recordSelected = null;
      },
      deep: true,
    },
  },
  async created() {
    if (this.formSettings.show) {
      this.field = "value";
      this.records = await this.getData(this.fieldSettings, this.formSettings);
    }
    if (this.record[this.field]) {
      let value = this.record[this.field];
      if (Array.isArray(value))
        value.forEach((val) => {
          if (val._id) val.displayname = val.displayname || val.name;
        });
      else {
        value = this.record[this.field];
        if (value.displayname || value.name)
          value.displayname = value.displayname || value.name;
      }
      this.recordSelected = this.record[this.field];
      if (this.multiple) this.records.push(...this.record[this.field]);
      else {
        if (this.record[this.field]._id)
          this.records.push(this.record[this.field]);
        // else
        //   this.records = await this.getData(
        //     this.fieldSettings,
        //     this.formSettings
        //   );
      }
    } else this.recordSelected = null;
  },
  computed: {
    hint() {
      let hint = "";
      if (this.recordSelected) {
        if (this.fieldSettings.setup.hint == "address")
          hint = `${this.recordSelected.address}, ${
            this.recordSelected.city
          }, ${
            this.recordSelected.country
              ? this.recordSelected.country.name || this.recordSelected.country
              : ""
          }`;
        if (
          this.fieldSettings.setup.hint == "delivery" &&
          this.recordSelected.eta
        )
          hint = `ETA: ${new Date(this.recordSelected.eta)
            .toISOString()
            .substr(0, 10)}`;
        if (this.fieldSettings.setup.hint == "description")
          hint = this.recordSelected.description;
      }
      return hint;
    },
  },
  methods: {
    add() {
      if (
        this.fieldSettings &&
        this.fieldSettings.setup.collection &&
        this.fieldSettings.setup.filters
      ) {
        let newRecord = `/${this.fieldSettings.setup.collection}/${this.fieldSettings.setup.filters.recordtype}/create/?user=${this.record.customer._id}`;
        window.open(newRecord, "_blank");
      }
    },
    async getData(source, form) {
      this.loading = true;

      if (source._id == "field" && !source.setup.filters._id) {
        return await this.getForms(
          this.parent
            ? this.parent.recordtype
            : this.record.type
            ? this.record.type._id || this.record.type
            : this.record.recordtype
        );
      } else {
        source.setup.filters = source.setup.filters || {};
        let field = await fieldCalculated(source, this.record);
        // if (field.filters._id && this.recordSelected)
        //   field.filters._id["$in"].push(
        //     Array.isArray(this.recordSelected)
        //       ? this.recordSelected.map((v) => v._id)
        //       : this.recordSelected._id
        //   );
        let parameters = [
          field.filters,
          {
            projection: {
              name: true,
              displayname: true,
              recordtype: true,
              collection: true,
            },
          },
        ];
        let data = {
          action: "find",
          data: parameters,
          limit: 500,
        };
        return service(field.setup.collection, data)
          .catch((error) => {
            throw error;
          })
          .then((response) => {
            //console.log("select", response);
            let options = response.map((obj) => {
              if (this.recordObj) {
                obj.displayname = obj.displayname || obj.name;
                return obj;
              } else
                return {
                  displayname: obj.displayname || obj.name,
                  _id: obj._id,
                };
            });
            let filtervalue = this.recordSelected;
            if (!Array.isArray(filtervalue)) filtervalue = [filtervalue];
            filtervalue.forEach((val) => {
              if (val) {
                if (val.displayname || val.name)
                  val.displayname = val.displayname || val.name;
                options.push(val);
              }
            });
            this.loading = false;
            return options;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
      this.loading = false;
    },
    async getForms(recordtype) {
      console.log(recordtype);
      this.loading = true;
      let fieldsObj = await getFields(this, this.record);
      let fields = [];
      let form = await service("forms", {
        action: "getForm",
        rid: recordtype,
      });
      form.tabs.forEach((tab) => {
        tab.sections.forEach((section) => {
          (section.columns || []).forEach((column) => {
            column.forEach((field) => {
              if (
                fieldsObj[field.field] && 
                ["field-text", "field-textarea", "field-boolean"].includes(
                  fieldsObj[field.field].setup.type
                )
              )
                fields.push({
                  _id: field.field,
                  displayname: field.displayname || field.name,
                });
            });
          });
          (section.fields || []).forEach((field) => {
            if (
              ["field-text", "field-textarea"].includes(
                fieldsObj[field.field].setup.type
              )
            )
              fields.push({
                _id: field.field,
                displayname: field.displayname || field.name,
              });
          });
          //   if (section.sublist) {
          //     this.subrecords.push({
          //       field: section.sublist.field,
          //       name: section.sublist.name,
          //     });
          //     (section.sublist.columns || []).forEach((column) => {
          //       this.columns[section.sublist.field] = this.columns[
          //         section.sublist.field
          //       ]
          //         ? this.columns[section.sublist.field]
          //         : [];
          //       this.columns[section.sublist.field].push(column);
          //     });
          //   }
        });
      });
      this.loading = false;
      return fields;
    },
  },
};
</script>

<template>
  <v-card tile>
    <v-toolbar dark color="primary">
      <v-toolbar-title>Warehouse Management Tools</v-toolbar-title>
    </v-toolbar>
    <v-tabs dark v-model="active">
      <v-tab :key="1">Ready To Pack</v-tab>
      <v-tab :key="2">Deliveries</v-tab>
      <v-tab :key="3">Pending Transfers</v-tab>
      <v-tab :key="4">Orders To Invoice</v-tab>
      <v-tab :key="5">Export Customs Clearance (v.Beta)</v-tab>
      <v-tab :key="6">Returns</v-tab>
      <v-tab :key="7">Stock Checks</v-tab>
      <v-tab :key="8">Reports</v-tab>
      <v-tab-item :key="1">
        <readytopack v-if="active == 0" />
      </v-tab-item>
      <v-tab-item :key="2">
        <deliveriescalendar v-if="active == 1" />
      </v-tab-item>
      <v-tab-item :key="3">
        <pendingtransfers v-if="active == 2" />
      </v-tab-item>
      <v-tab-item :key="4">
        <orderstoinvoice v-if="active == 3" />
      </v-tab-item>
      <v-tab-item :key="5">
        <exportcustomsclearance v-if="active == 4" />
      </v-tab-item>
      <v-tab-item :key="6">
        <returnrequest v-if="active == 5" />
      </v-tab-item>
      <v-tab-item :key="7">
        <stockcheck v-if="active == 6" />
      </v-tab-item>
      <v-tab-item :key="8">
        <reports v-if="active == 7" />
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import readytopack from "../components/tools/readytopack/View";
import deliveriescalendar from "../components/tools/deliveriescalendar/View";
import reports from "../components/tools/reports/View";
import orderstoinvoice from "../components/tools/orderstoinvoice/View";
import pendingtransfers from "../components/tools/pendingtransfers/View";
import exportcustomsclearance from "../components/tools/exportcustomsclearance/View";
import returnrequest from "../components/tools/returnrequest/View";
import stockcheck from "../components/tools/stockcheck/View";
/* eslint-disable */
export default {
  data() {
    return {
      loading: true,
      active: null,
    };
  },
  components: {
    readytopack,
    deliveriescalendar,
    reports,
    orderstoinvoice,
    pendingtransfers,
    exportcustomsclearance,
    returnrequest,
    stockcheck,
  },
};
</script>
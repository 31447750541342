 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          :search="search"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
          @current-items="getFiltered"
        >
          <template v-slot:item.date="{ item }">
            <span>{{ new Date(item.date).toISOString().substr(0, 10) }}</span>
          </template>
          <template v-slot:item.etd="{ item }">
            <span>{{
              item.etd ? new Date(item.etd).toISOString().substr(0, 10) : ""
            }}</span>
          </template>
          <template v-slot:item.planneddateofshipment="{ item }">
            <span>{{
              item.planneddateofshipment
                ? new Date(item.planneddateofshipment)
                    .toISOString()
                    .substr(0, 10)
                : ""
            }}</span>
          </template>
          <template v-slot:item.transaction="{ item }">
            <router-link
              :to="{
                path: `/${item.transaction.collection}/${item.transaction.recordtype}/view/${item.transaction._id}`,
              }"
              class="link"
              >{{
                item.transaction.displayname || item.transaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:item.createdfromtransaction="{ item }">
            <router-link
              v-if="item.createdfromtransaction"
              :to="{
                path: `/${item.createdfromtransaction.collection}/${item.createdfromtransaction.recordtype}/view/${item.createdfromtransaction._id}`,
              }"
              class="link"
              >{{
                item.createdfromtransaction.displayname ||
                item.createdfromtransaction.name
              }}</router-link
            >
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.additionalservice="{ item }">
            <router-link
              v-for="service in item.additionalservice"
              :key="service._id"
              :to="{
                path: `/${service.collection}/${service.recordtype}/view/${service._id}`,
              }"
              class="link"
              >{{ service.displayname || service.name }}</router-link
            >
          </template>
          <template v-slot:item.correlated="{ item }">
            <router-link
              v-for="po in item.correlated"
              :key="po._id"
              :to="{
                path: `/${po.collection}/${po.recordtype}/view/${po._id}`,
              }"
              class="link"
              >{{ po.displayname || po.name }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-switch v-model="open" label="Only Open"></v-switch>
                </v-col>
                <v-spacer></v-spacer>

                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-row>
                    <v-col>
                      <v-select
                        dense
                        v-model="vendor"
                        :items="vendors"
                        label="Vendors"
                        outlined
                        item-text="name"
                        item-value="_id"
                        hide-details
                        clearable
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        dense
                        v-model="deliverymethod"
                        :items="deliverymethods"
                        label="Delivery Method"
                        outlined
                        item-text="name"
                        item-value="_id"
                        hide-details
                        clearable
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        dense
                        v-model="location"
                        :items="locations"
                        label="Location"
                        outlined
                        item-text="name"
                        item-value="_id"
                        hide-details
                        clearable
                        multiple
                      ></v-select>
                    </v-col>
                    <v-col
                      ><v-btn
                        @click="getData()"
                        block
                        tile
                        color="primary"
                        :disabled="loading"
                        >Refresh</v-btn
                      ></v-col
                    >
                    <v-spacer></v-spacer>
                    <v-col>
                      <v-btn @click="updatePrice()" block tile color="primary"
                        >Update Open Purchase Price</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-divider></v-divider>
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Location", value: "warehouse" },
        { text: "Destination", value: "destination" },
        { text: "Date", value: "date" },
        { text: "Purchase Order", value: "transaction" },
        { text: "Created From", value: "createdfromtransaction" },
        { text: "Vendor", value: "customer" },
        { text: "Delivery Method", value: "deliverymethod" },
        { text: "ETD", value: "etd" },
        { text: "Requested Shipping Date", value: "planneddateofshipment" },
        { text: "Item", value: "item" },
        { text: "PO Price", value: "price" },
        { text: "Vendor Price", value: "purchaseprice" },
        { text: "Ordered", value: "quantity" },
        { text: "Billed", value: "quantitybilled" },
        { text: "Received", value: "quantityreceived" },
        { text: "Open", value: "quantityopen" },
        { text: "Memo", value: "memo" },
        { text: "Custom Product infomation", value: "memoline" },
      ],
      items: [],
      rows: [100, -1],
      search: "",
      show: false,
      filteredItems: [],
      vendor: [],
      vendors: [],
      deliverymethod: [],
      deliverymethods: [],
      location: [],
      locations: [],
      open: true,
    };
  },
  async created() {
    await this.getData("created");
  },
  watch: {
    // async vendor(value) {
    //   await this.getData("vendor");
    // },
    // async deliverymethod(value) {
    //   await this.getData("deliverymethod");
    // },
    async open(value) {
      await this.getData("open");
    },
  },
  methods: {
    async getData(init) {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getOpenPurchaseOrders",
          deliverymethods: this.deliverymethod,
          location: this.location,
          vendor: this.vendor,
          open: this.open,
        },
        false,
        true
      );
      this.items = result.items;
      if (init == "created" || init == "open") {
        this.vendors = [];
        this.deliverymethods = [];
        this.locations = [];

        result.items.forEach((item) => {
          this.vendors.push(item.customer);
          this.deliverymethods.push(item.delivery);
          this.locations.push(item.location);
        });
      }
      this.loading = false;
    },
    async updatePrice() {
      this.loading = true;
      let purchaseorders = [];
      for (let line of this.items) {
        try {
          if (
            line.quantityopen > 0 &&
            line.price != line.purchaseprice &&
            line.purchaseprice &&
            !purchaseorders.includes(line.transaction._id)
          ) {
            purchaseorders.push(line.transaction._id);
            let result = await service(
              "orders",
              {
                action: "updateTransaction",
                order: line.transaction._id,
              },
              false,
              true
            );
          }
        } catch (error) {
          alert(error.message);
        }
      }
      await this.getData("update");
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    getFiltered(e) {
      this.filteredItems = e;
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    exportData: exportData,
  },
};
</script>
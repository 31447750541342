 <template>
  <div>
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          loading-text="Loading... Please wait"
          item-key="_id"
          v-model="selected"
          show-select
          :search="search"
          :single-select="singleSelect"
          :footer-props="{ itemsPerPageOptions: rows }"
          :custom-filter="filter"
          :custom-sort="sort"
        >
          <template v-slot:item.quantity="{ item }">
            <v-text-field
              label="Regular"
              single-line
              type="number"
              v-model="item.quantity"
            ></v-text-field>
          </template>
          <template v-slot:item.item="{ item }">
            <router-link
              :to="{
                path: `/${item.item.collection}/${item.item.recordtype}/view/${item.item._id}`,
              }"
              class="link"
              >{{ item.item.displayname || item.item.name }}</router-link
            >
          </template>
          <template v-slot:item.customer="{ item }">
            <router-link
              :to="{
                path: `/${item.customer.collection}/${item.customer.recordtype}/view/${item.customer._id}`,
              }"
              class="link"
              >{{
                item.customer.displayname || item.customer.name
              }}</router-link
            >
          </template>
          <template v-slot:top>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-text-field
                    dense
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col>
                  <exportData :headers="headers" :items="items" />
                </v-col>
                <v-col>
                  <v-select
                    :items="locations"
                    v-model="location"
                    label="Location"
                    item-text="name"
                    item-value="_id"
                    outlined
                    clearable
                    multiple
                    class="mt-2 mb-2"
                    dense
                  ></v-select>
                </v-col>
                <v-col>
                  <v-combobox
                    :items="vendors"
                    v-model="vendor"
                    label="Vendor"
                    item-text="displayname"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    hide-details
                    class="mt-2 mb-2"
                    :loading="loading"
                  ></v-combobox>
                </v-col>
                <v-col>
                  <v-text-field
                    dense
                    v-model="daysa"
                    label="Days (group A)"
                    outlined
                    min="1"
                    type="number"
                    class="mt-2"
                  ></v-text-field>
                  <v-text-field
                    dense
                    v-model="daysb"
                    label="Days (group B)"
                    hide-details
                    outlined
                    min="1"
                    type="number"
                    class="mt-1 mb-2"
                  ></v-text-field>
                </v-col>
                <v-col>
                  <v-btn
                    @click="getData()"
                    block
                    tile
                    color="primary"
                    class="mt-2 mb-2"
                    >Refresh</v-btn
                  >
                </v-col>
                <v-spacer></v-spacer>

                <v-col class="text-right">
                  <v-btn icon @click="show = !show">
                    <v-icon>{{
                      show ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <toolfooter
                    :items="items"
                    :selected="selected"
                    field="quantity"
                    :defvendor="vendor"
                    stockorder="true"
                    v-if="!loading"
                  />
                </div>
              </v-expand-transition>
            </v-container>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>
<script>
/* eslint-disable */
import service from "../../../api/index";
import filter from "../../dataTable/filter";
import footer from "../backorderitems/footer";
import exportData from "../../dataTable/exportData";
import sort from "../../dataTable/sort";
export default {
  data() {
    return {
      loading: false,
      headers: [
        { text: "Item", value: "item" },
        { text: "Vendor", value: "customer" },
        { text: "To Order", value: "quantity" },
        { text: "Avg. Sales", value: "averagesales" },
        { text: "Available", value: "quantityavailable" },
        { text: "On Order", value: "quantityonorder" },
        { text: "BO", value: "quantitybackorder" },
        { text: "Pref. Stock", value: "preferedstocklevel" },
        { text: "Manufacturer Stock", value: "manufacturerstock" },
        { text: "Stock Category", value: "stockcategory" },
        { text: "stockgroup", value: "stockgroup" },
        { text: "Currency", value: "currency" },
        { text: "Weight", value: "weight" },
        { text: "Purchase Price", value: "purchaseprice" },
      ],
      items: [],
      rows: [-1],
      singleSelect: false,
      selected: [],
      search: "",
      show: false,
      vendor: null,
      vendors: [],
      currency: null,
      currencies: [],
      location: [],
      locations: [],
      message: "",
      countries: [],
      address: {},
      deliverymethod: null,
      deliverymethods: [],
      quantity: 0,
      weight: 0,
      amount: 0,
      fleetorder: true,
      open: true,
      dropship: true,
      daysa: 30,
      daysb: 21,
      defaultDays: {
        "5e14ac000398a160b00f5855": {
          daysa: 50,
          daysb: 50,
        },
        "5e14abee0398a160b00f566a": {
          daysa: 50,
          daysb: 50,
        },
        "65d8500580b7993b4539e56b": {
          daysa: 90,
          daysb: 90,
        },
        "5e6f33b839d811070f6cca15": {
          daysa: 50,
          daysb: 40,
        },
        "63ca622d81e7b5867a6f21ee": {
          daysa: 14,
          daysb: 14,
        },
      },
    };
  },
  watch: {
    vendor(value) {
      if (value) {
        this.daysa = this.defaultDays[value._id]
          ? this.defaultDays[value._id].daysa
          : 30;
        this.daysb = this.defaultDays[value._id]
          ? this.defaultDays[value._id].daysb
          : 21;
      }
    },
  },
  async created() {
    this.loading = true;
    this.vendors = await service("users", {
      action: "find",
      data: [
        {
          recordtype: "vendor",
          _id: {
            $in: [
              //"5e14ac000398a160b00f5855",
              "65d8500580b7993b4539e56b",
              "5e6f33b839d811070f6cca15",
              "63ca622d81e7b5867a6f21ee",
            ],
          },
        },
      ],
    });
    this.locations = await service("accounting", {
      action: "find",
      data: [
        {
          recordtype: "location",
          _id: {
            $in: ["5e0dbaba9e33df43f0b3a495", "61e14c6ae4a35a668517a8fe"],
          },
        },
      ],
    });
    await this.getData();

    this.loading = false;
  },
  methods: {
    async getData() {
      this.loading = true;
      let result = await service(
        "orders",
        {
          action: "getPOPlanner",
          location: this.location,
          vendor: this.vendor ? this.vendor._id : null,
          daysa: parseInt(this.daysa),
          daysb: parseInt(this.daysb),
        },
        false,
        true
      );
      this.items = result.items;
      this.loading = false;
    },
    filter(value, search, item) {
      return filter(value, search, item);
    },
    sort(items, sortBy, sortDesc) {
      return sort(items, sortBy, sortDesc);
    },
  },
  components: {
    toolfooter: footer,
    exportData: exportData,
  },
};
</script>